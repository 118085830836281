import { ICartResponse } from "./../../../../redux/src/lib/dto/cart.types";
import { ICartB2B } from "@ecp-redux/dto/cartB2B.types";
import { FieldValues } from "react-hook-form";
import { z } from "zod";
import {
  ILayoutPageBuilder,
  PageCategoryType,
  SectionType,
  TPlatform,
} from "@ecp-pageTypes";
import { TUrl } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { ICheckboxProps } from "../shared/components/CheckboxesList/CheckboxesList";
import mapValues from "lodash.mapvalues";

// for checking changes in complex data structures
export const asJSON = (val: unknown) => {
  return JSON.stringify(val);
};

export const noEmptyValue = (value: string | null | undefined): boolean =>
  value !== "" && value !== null && value !== undefined;

export const isTruthy = (setting: string): boolean => setting === "true";

export const capitalizeFirstLetter = (word: string): string =>
  word.charAt(0).toUpperCase() + word.slice(1);

export const convertUrlToImage = async (imageUrl: string) => {
  if (imageUrl === "") return;

  const img = new Image();
  img.src = imageUrl;

  await img.decode?.();
  return img;
};

export const sortPages = (data: ILayoutPageBuilder[] | undefined) => {
  return data
    ?.map((element: ILayoutPageBuilder) => element.name)
    .sort((a, b) => sortIgnoringCapitalization(a, b));
};

export const sortIgnoringCapitalization = (a: string, b: string) => {
  return a.toLowerCase().localeCompare(b.toLowerCase());
};

export const isErrorByCode = (response: any, codes: number[]): boolean => {
  if (response?.error?.data?.code) {
    return codes.includes(response.error.data.code);
  }
  if (response?.data?.code) {
    return codes.includes(response.data.code);
  }
  return false;
};

export const checkSlidesPerView = (
  numberFromSettings: number,
  isMobile: boolean
): number => {
  if (numberFromSettings > 2 && isMobile) {
    return 2;
  }
  if (numberFromSettings < 2 && isMobile) {
    return numberFromSettings;
  }
  return numberFromSettings;
};
export const replaceBetween = (
  phrase: string,
  start: string,
  end: string,
  newFragment: string
): string => {
  const regex = new RegExp(`${start}(.*?)${end}`, "g");
  return phrase.replace(regex, `${start}${newFragment}${end}`);
};

export const isClientSide = (): boolean => typeof window !== "undefined";
export const isPortalSide = (): boolean =>
  process.env["NEXT_PUBLIC_APP_NAME"] === "portal";

export const getImageUrlOrFallback = (
  imageUrl: TUrl,
  fallbackUrl: TUrl
): TUrl => {
  return imageUrl?.length > 0 ? imageUrl : fallbackUrl;
};

export const formatPrice = (
  price: number,
  currencyShort?: string,
  showDecimals = true
) => {
  if (price === undefined || price === null) return "";

  const fractionDigits = !showDecimals && Number.isInteger(price) ? 0 : 2;

  return `${price.toLocaleString("pl-PL", {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  })} ${currencyShort ?? ""}`;
};

export const formatPercentage = (rate: number, unit?: string) => {
  if (rate === undefined || rate === null) return "";
  const fixed = rate % 1 === 0 ? 0 : 2;
  return `${rate.toFixed(fixed)}${unit ?? ""}`.replace(`.`, `,`);
};

export function getDefaultsFromZod<TSchema extends z.AnyZodObject>(
  schema: TSchema
): (typeof schema)["_type"] {
  function getDefaultValue(schema: z.ZodTypeAny): unknown {
    if (schema instanceof z.ZodDefault) return schema._def.defaultValue();
    if (!("innerType" in schema._def)) return undefined;
    return getDefaultValue(schema._def.innerType);
  }

  return Object.fromEntries(
    Object.entries(schema.shape).map(([key, value]) => {
      return [key, getDefaultValue(value as z.ZodTypeAny)];
    })
  );
}

export const checkIfMoreThanOneOtherCheckbox = (
  checkboxes: Omit<ICheckboxProps<FieldValues>, "register" | "textStyles">[]
): boolean => {
  return checkboxes.filter((checkbox) => checkbox.label).length > 1;
};

export const transformPageTypeToSectionType = (
  pageType: PageCategoryType
): SectionType => {
  if (pageType === PageCategoryType.HEADER) {
    return SectionType.HEADER;
  }
  if (pageType === PageCategoryType.FOOTER) {
    return SectionType.FOOTER;
  }
  return SectionType.MAIN;
};

export const getImageNameFromUrl = (imageUrl: string): string => {
  const splited = imageUrl.split("/");
  return splited[splited.length - 1];
};

export const getJustifyContentValue = (spaceBetween: boolean) => {
  return spaceBetween ? "space-between" : "flex-end";
};

/**
 * Formats a number by capping it at a specified value.
 * If the number exceeds the cap, returns 'cap+'.
 *
 * @param {number} num - The number to format.
 * @param {number} cap - The maximum value before adding '+'.
 * @return {string} - The formatted number as a string.
 *
 * @example
 * // Returns '99+' since 120 exceeds the default cap of 99
 * formatNumberWithCap(120);
 */
export const formatNumberWithCap = (num: number, cap = 99) =>
  num > cap ? `${cap}+` : num.toString();

export const checkBoolean = (value: "true" | "false"): boolean =>
  value === "true";

export const mapPlatformToSymbol = (platform: TPlatform) => {
  switch (platform) {
    case "MOBILE":
      return "m";
    case "DESKTOP":
      return "d";
    default:
      return "d";
  }
};

export const handleKeyDown = (
  e: React.KeyboardEvent,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (...args: any[]) => void,
  keys = ["Enter", " "]
) => {
  if (keys.includes(e.key)) {
    callback();
    e.preventDefault();
  }
};

export const isAbsoluteUrl = (href: string) => {
  return href.startsWith("http://") || href.startsWith("https://");
};

export const isB2BCartResposne = (
  res: ICartB2B | ICartResponse
): res is ICartB2B => {
  return "hasNotificationErrors" in res;
};

export const checkFont = (
  family: string,
  weight: string,
  fontWeights: {
    [family: string]: number[];
  }
) => {
  const getClosestFontWeight = (
    requestedWeight: number,
    availableWeights: number[]
  ) => {
    if (availableWeights?.length === 1) return availableWeights[0];
    return availableWeights?.reduce((closest, current) =>
      Math.abs(current - requestedWeight) < Math.abs(closest - requestedWeight)
        ? current
        : closest
    );
  };

  const weightMap: { [key: string]: number } = {
    Weight100: 100,
    Weight200: 200,
    Weight300: 300,
    REGULAR: 300,
    Weight400: 400,
    SEMIBOLD: 400,
    Weight500: 500,
    Weight600: 600,
    BOLD: 600,
    Weight700: 700,
    Weight800: 800,
    Weight900: 900,
  };

  const requestedWeight = weightMap[weight];

  const availableWeights =
    fontWeights[
      family.includes("_")
        ? family.split("_")[0] +
          " " +
          capitalizeFirstLetter(family.split("_")[1])
        : family
    ];
  const closestFontWeight: number = getClosestFontWeight(
    requestedWeight,
    availableWeights
  );

  const weightFileMap: { [key: number]: string } = {
    100: "-Thin.ttf",
    200: "-ExtraLight.ttf",
    300: "-Light.ttf",
    400: "-Regular.ttf",
    500: "-Medium.ttf",
    600: "-SemiBold.ttf",
    700: "-Bold.ttf",
    800: "-ExtraBold.ttf",
    900: "-Black.ttf",
  };
  const weightFile = weightFileMap[closestFontWeight];

  if (family.includes("_")) {
    family = family.split("_")[0] + capitalizeFirstLetter(family.split("_")[1]);
  }

  return `/fonts/${family.split("-")[0]}/${family}${weightFile}`;
};

export const concatAddress = <
  T extends {
    street?: string;
    street2?: string;
    houseNo?: string;
    apartmentNo?: string;
    postCode?: string;
    zipCode?: string;
    city?: string;
    streetNumber?: string;
    flatNumber?: string;
  },
>({
  street,
  street2,
  houseNo,
  apartmentNo,
  postCode,
  flatNumber,
  zipCode,
  streetNumber,
  city,
}: T) => {
  const addressFirstLine = [
    [street, street2].filter(Boolean).join(" "),
    [houseNo ?? streetNumber, apartmentNo ?? flatNumber]
      .filter(Boolean)
      .join("/"),
  ]
    .filter(Boolean)
    .join(" ");

  const addressSecondLine = [postCode ?? zipCode, city]
    .filter(Boolean)
    .join(" ");

  return {
    addressFirstLine,
    addressSecondLine,
    addressOneLine: [addressFirstLine, addressSecondLine]
      .filter(Boolean)
      .join(", "),
  };
};

export const extractLanguageCode = (lang: string | undefined) => {
  return lang?.split("_")[0] ?? "pl";
};

export const extractCountryCode = (lang: string | undefined) => {
  return lang?.split("_")[1] ?? "PL";
};

export const safeParseJSON = <T>(input: string) => {
  if (typeof input === "object" && input !== null) {
    return input;
  }
  if (typeof input === "string") {
    try {
      return JSON.parse(input) as T;
    } catch (error) {
      console.error("Invalid JSON string:", error);
      return input;
    }
  }
  return null;
};

export const enrichSanitizedAddressWithType = <T extends { nip?: string }>(
  address: T
) => {
  const sanitizedAddress = mapValues(address, (value) =>
    value === null ? "" : value
  );

  return {
    ...sanitizedAddress,
    addressType: sanitizedAddress.nip ? "company" : "private",
  };
};
