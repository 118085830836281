import absoluteUrl from "next-absolute-url";
import { createWrapper } from "next-redux-wrapper";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { api } from "@ecp-redux/api/api";
import { extractTenantFromUrl } from "@ecp-redux/api/tenantHelpers";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  portalApiError500Handler,
  portalApiErrorUnauthorizedUserHandler,
  portalApiSnackbarErrorHandler,
} from "./middlewares/portalApiErrorHandler";
import alertsSlice from "./slices/alertsSlice";

export const portalReducers = {
  alerts: alertsSlice.reducer,
};

export const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  ...portalReducers,
});

export const makeStore = (context: any) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        thunk: {
          extraArgument: {
            cookies: context.req?.headers.cookie,
            themeTenant: extractTenantFromUrl(context.req?.url),
            origin: context?.req ? absoluteUrl(context.req).origin : undefined,
            url: context.req?.url,
            locale: context.locale,
            currency: context.req?.headers.cookie?.match(/curr=([^;]+)/)?.[1],
          },
        },
      })
        .concat(api.middleware)
        .concat(portalApiSnackbarErrorHandler)
        .concat(portalApiError500Handler)
        .concat(portalApiErrorUnauthorizedUserHandler),
  });

export type AppStore = ReturnType<typeof makeStore>;
export type RootStatePortal = ReturnType<typeof rootReducer>;
type AppDispatch = AppStore["dispatch"];

export const usePortalDispatch = () => useDispatch<AppDispatch>();
export const usePortalSelector: TypedUseSelectorHook<RootStatePortal> =
  useSelector;

export const portalStoreWrapper = createWrapper<AppStore>(makeStore, {
  debug: process.env.NODE_ENV === "development",
});

export const makeStoreForTest = (preloadedState?: RootStatePortal) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      })
        .concat(api.middleware)
        .concat(portalApiSnackbarErrorHandler)
        .concat(portalApiError500Handler)
        .concat(portalApiErrorUnauthorizedUserHandler),
  });
