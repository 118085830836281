import { useEffect, useState, useRef } from "react";
import { ImageProps } from "next/image";
import NextImage from "next/image";

const Image: React.FC<ImageProps> = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (props.loading === "eager" || props.priority) {
      setIsVisible(true);
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.disconnect(); // Przestaje obserwować po załadowaniu
          }
        });
      },
      { rootMargin: "200px" } // Możesz zwiększyć ten margines, aby obrazy ładowały się wcześniej
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => observer.disconnect();
  }, [props.loading, props.priority]);

  return (
    <NextImage ref={imgRef} loading={isVisible ? "eager" : "lazy"} {...props} />
  );
};

export default Image;
