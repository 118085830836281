import { IDisplaySettingsSection } from "../../dto/themeSettings/StyledSection.types";
import { IDisplaySettingsSlot } from "../../dto/themeSettings/StyledSlot.types";
import { PageCategoryType, TRegularBoxType } from "./union.types";

export type TPlatform = "MOBILE" | "DESKTOP";

export interface IPortalSection {
  id: number;
  customCss: string;
  uuid: string;
  sectionOrder: number;
  mobileSubSection: boolean;
  slots: IPortalSlot[];
  displaySettings: Partial<IDisplaySettingsSection>;
}

export interface IPortalSlot {
  id: number;
  customCss: string;
  sectionId: number;
  columns: number;
  groupingMode: string;
  stacks: IPortalStack[];
  sections: IPortalSection[];
  displaySettings: Partial<IDisplaySettingsSlot>;
  slotOrder?: number;
}

export interface IPortalStack {
  publications: IPortalPublication[];
}

export type IPortalBoxData = {
  content: Record<string, any>;
  contentSettings: Record<string, any> | null;
  displaySettings: Record<string, any> | null;
  messages: Record<string, any>;
  settings: Record<string, any>;
};

export interface IPortalPublication {
  id: number;
  box: IPortalBox<IPortalBoxData> | undefined;
  publicationOrder: number;
  alignment: string; // to type
  width: string; // to type
  boxId: number;
}

export interface IPortalBox<T extends IPortalBoxData = IPortalBoxData> {
  id: number;
  name: string;
  type: TRegularBoxType;
  local: boolean;
  content: T["content"];
  messages: T["messages"];
  settings: T["settings"];
  contentSettings: T["contentSettings"];
  displaySettings: T["displaySettings"];
  version: number;
  customCss: string;
  customJs: string;
}

export interface IPortalPage {
  id: number;
  name: string;
  url: string;
  type: PageCategoryType;
  contents: TPageContents;
  sections: IPortalSection[];
  header: IPortalPage | null;
  footer: IPortalPage | null;
}

export type TPageContents = [
  {
    id: number | null;
    title: string;
    description: string;
    keywords: string;
    language: string;
  },
];

export enum SectionType {
  HEADER = "HEADER",
  FOOTER = "FOOTER",
  MAIN = "MAIN",
  POPUP = "POPUP",
}

export type IPortalBoxes = {
  [index in string | number]: IPortalBox<IPortalBoxData>;
};

export type PortalPageWithBoxes = {
  page: IPortalPage;
  boxes: IPortalBoxes;
  headerBoxes: IPortalBoxes;
  footerBoxes: IPortalBoxes;
};

export interface IPortalPageResponse {
  page: {
    id: number;
    name: string;
    url: string;
    type: PageCategoryType;
    productSku: string | null;
    articleId: number | null;
    productCategoryCode: string | null;
    content: {
      id: number;
      version: number;
      title: string | null;
      description: string | null;
      productCategoryDescription: string | null;
      keywords: string | null;
      language: string;
    }[];
    sections: {
      id: number;
      customCss: string;
      uuid: string;
      sectionOrder: number;
      mobileSubSection: boolean;
      slots: {
        id: number;
        customCss: string;
        sectionId: number;
        columns: number;
        groupingMode: string;
        stacks: {
          publications: {
            id: number;
            publicationOrder: number;
            alignment: string;
            width: string;
            boxId: number;
          }[];
        }[];
        sections: IPortalPageResponse["page"]["sections"]["0"][];
        displaySettings: Partial<IDisplaySettingsSlot>;
        slotOrder?: number;
      }[];
      displaySettings: Partial<IDisplaySettingsSection>;
    }[];
    header: IPortalPageResponse["page"] | null;
    footer: IPortalPageResponse["page"] | null;
  };
  boxes: IPortalBoxes;
  headerBoxes?: IPortalBoxes;
  footerBoxes?: IPortalBoxes;
}
