import { ToastContainer } from "react-toastify";
import styled, { createGlobalStyle, css } from "styled-components";
import {
  IGlobalSnackbarObject,
  SlideFromDirections,
  SnackbarAnimationType,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { convertColorIdToHex } from "../../../settingsPatterns/settingsPatterns.methods";

interface GlobalStyleProps {
  snackbarValue: {
    animationType: SnackbarAnimationType;
    slideDirection?: SlideFromDirections;
    customCss?: string;
  };
  offset: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
}

export const StyledPortalSnackbar = styled.div<
  IGlobalSnackbarObject & { isEmptySnackbar: boolean }
>(
  ({
    verticalPadding,
    horizontalPadding,
    maxWidth,
    backgroundColor,
    borderStyle,
    borderColor,
    borderRadius,
    borderThickness,
    theme,
    isEmptySnackbar,
    icon: {
      iconSize,
      iconPosition,
      iconUrl,
      iconSpacing,
      iconMainColor,
      iconHoverColor,
    },
  }) => {
    return css`
      display: flex;
      justify-content: space-between;
      padding-top: ${verticalPadding}px;
      padding-bottom: ${verticalPadding}px;
      max-width: ${maxWidth}px;
      border-style: ${borderStyle};
      border-width: ${borderThickness}px;
      border-radius: ${borderRadius}px;
      border-color: ${convertColorIdToHex(borderColor, theme.colorPalette)};
      box-shadow:
        0 1px 3px 0 rgb(0 0 0 / 0.1),
        0 1px 2px -1px rgb(0 0 0 / 0.1);
      ${isEmptySnackbar ? `width: 200px;` : ""}

      background-color: ${convertColorIdToHex(
        backgroundColor,
        theme.colorPalette
      )};

      .message {
        padding-left: ${horizontalPadding}px;
        padding-right: ${horizontalPadding}px;
      }

      .snackbar-icon-close {
        padding-right: 0.75rem;
      }

      .content {
        display: flex;

        padding-left: ${horizontalPadding}px;
        padding-right: ${horizontalPadding}px;

        .svg {
          height: ${iconSize}px;
          width: ${iconSize}px;
          background-color: ${convertColorIdToHex(
            iconMainColor,
            theme.colorPalette
          )};
          mask: url(${iconUrl}) no-repeat center / contain;
          -webkit-mask: url(${iconUrl}) no-repeat center / contain;
        }

        & {
          display: flex;
          gap: ${iconSpacing}px;
          align-items: center;
        }

        & > i,
        & > i {
          order: ${iconPosition === "LEFT" ? -1 : 1};
        }

        & > i:before,
        & > i:before {
          display: block;
          content: "";
          background: url(${iconUrl});
          background-repeat: no-repeat;
          background-size: 100%;
          height: ${iconSize}px;
          width: ${iconSize}px;
        }

        &:hover {
          .svg {
            background-color: ${convertColorIdToHex(
              iconHoverColor,
              theme.colorPalette
            )};
          }
        }

        /* Smartphones and touchscreens - Fix for stop applying hover styles after clicking on button on smartphones and touchscreens (ECP-8289) */
        @media ((hover: none) or (hover: hover)) and (pointer: coarse) {
          &:hover {
            .svg {
              background-color: ${convertColorIdToHex(
                iconHoverColor,
                theme.colorPalette
              )};
            }
          }
        }
      }
    `;
  }
);

export const StyledPortalToastContainer = styled(ToastContainer)`
  .Toastify__toast-body {
    padding: 0px;
  }

  .Toastify__toast {
    min-height: auto;
    padding: 0px;
    background-color: unset;
    cursor: auto;
    box-shadow: none;
    padding: 2px;
  }

  &&&.Toastify__toast-container {
    width: auto;
  }
`;

export const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  @keyframes fade-in {
 0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @keyframes fade-out {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }
  
  @keyframes slide-in-right {
    0% { transform: translateX(100%); opacity: 0; }
    100% { transform: translateX(0); opacity: 1; }
  }

  @keyframes slide-out-right {
    0% { transform: translateX(0); opacity: 1; }
    100% { transform: translateX(100%); opacity: 0; }
  }

  @keyframes slide-in-left {
    0% { transform: translateX(-100%); opacity: 0; }
    100% { transform: translateX(0); opacity: 1; }
  }

  @keyframes slide-out-left {
    0% { transform: translateX(0); opacity: 1; }
    100% { transform: translateX(-100%); opacity: 0; }
  }

  @keyframes slide-in-top {
    0% { transform: translateY(-100%); opacity: 0; }
    100% { transform: translateY(0); opacity: 1; }
  }

  @keyframes slide-out-top {
    0% { transform: translateY(0); opacity: 1; }
    100% { transform: translateY(-100%); opacity: 0; }
  }

  @keyframes slide-in-bottom {
    0% { transform: translateY(100%); opacity: 0; }
    100% { transform: translateY(0); opacity: 1; }
  }

  @keyframes slide-out-bottom {
    0% { transform: translateY(0); opacity: 1; }
    100% { transform: translateY(100%); opacity: 0; }
  }

  ${({ snackbarValue }) => {
    switch (snackbarValue.animationType) {
      case "FADE":
        return `
          .toast-custom-enter {
            animation: fade-in 0.5s ease-in-out both;
          }
          .toast-custom-exit {
            animation: fade-out 0.5s ease-in-out both;
          }
        `;
      case "SLIDE": {
        const slideDirection =
          snackbarValue.slideDirection?.toLowerCase() ?? "right";
        return `
            .toast-custom-enter {
              animation: slide-in-${slideDirection} 0.5s ease-in-out both;
            }
            .toast-custom-exit {
              animation: slide-out-${slideDirection} 0.5s ease-in-out both;
            }
          `;
      }
      case "CUSTOM":
        return snackbarValue.customCss ?? ``;
      case "NONE":
        return `
            .toast-custom-enter {
              animation: fade-in 0.01s ease-in-out both;
              opacity: 1;
            }
            .toast-custom-exit {
              animation: fade-out 0.01s ease-in-out both;
              opacity: 0;
            }
          `;
      default:
        return "";
    }
  }}

.Toastify__toast-container--center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}

.Toastify__toast-container--left-center {
  top: 50%;
  left: 0;
  transform: translate(0, -50%) !important;
}

.Toastify__toast-container--right-center {
  top: 50%;
  right: 0;
  transform: translate(0, -50%) !important;
}

.Toastify__toast-container--bottom-left {
  bottom: 0;
  left: 0;
  right: unset;
}

.Toastify__toast-container--bottom-center {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) !important;
}

.Toastify__toast-container--bottom-right {
  bottom: 0;
  right: 0;
  left: unset;
}

.Toastify__toast-container--top-left {
  top: 0;
  left: 0;
  right: unset;
}

.Toastify__toast-container--top-center {
  top: 0;
  left: 50%;
  transform: translateX(-50%) !important;
}

.Toastify__toast-container--top-right{
  top: 0;
  right: 0;
  left: unset;
}

  .Toastify__toast-container--top-left,
  .Toastify__toast-container--top-center,
  .Toastify__toast-container--top-right,
  .Toastify__toast-container--bottom-left,
  .Toastify__toast-container--bottom-center,
  .Toastify__toast-container--bottom-right,
  .Toastify__toast-container--center-center,
  .Toastify__toast-container--left-center,
  .Toastify__toast-container--right-center {
    ${({ offset }) => {
      const translateY = offset.top - offset.bottom;
      const translateX = offset.left - offset.right;

      return css`
        translate: ${translateX}px ${translateY}px !important;
      `;
    }}
  }
`;
