export const MEDIA_ASSETS_RESPONSE = {
  Brand: {
    title: {
      mediaAssetsType: "IMAGE",
      image: {
        defaultIconUrl:
          "https://uat-minio-ecp.nd0.pl/ecp-media-tenant-belutti-uat/products-images/a7bfc525183c9e5441cf397cde469b36675eea45_BAM129_C002_1.jpg",
      },
      animatedGifUrl: "/",
      activeIconUrl: "/",
      disabledIconUrl: "/",
    },

    values: {
      nike: {
        mediaAssetsType: "COLOR",
        color: {
          id: "color$$1",
          custom: "#000000",
        },
      },
      Belutti: {
        mediaAssetsType: "COLOR",
        color: {
          id: "color$$1",
          custom: "#FFFFFF",
        },
      },
      puma: {
        mediaAssetsType: "NONE",
      },
    },
  },
  Ubrania: {
    title: {
      mediaAssetsType: "COLOR",
      color: {
        id: "color$$2",
        custom: "#FFFFFF",
      },
    },
    values: null,
  },
};
