import { createContext, useCallback, useContext, useState } from "react";

export type EffectsData = {
  height?: number;
  sumHeight?: number;
  sectionUuid?: string;
  sectionId?: number;
  sticky?: boolean;
  offset?: number;
  isHeaderSection?: boolean;
  zIndex?: number;
  ref?: HTMLDivElement;
  sectionName?: string;
};

export const SectionEffectsContext = createContext<{
  effectsData: EffectsData[];
  isAnySectionSticky: boolean;
  setSectionEffectsData: () => void;
}>({
  effectsData: [],
  isAnySectionSticky: false,
  setSectionEffectsData: () => null,
});

export function useSectionEffectsContext() {
  const context = useContext(SectionEffectsContext);
  if (!context) {
    console.log(
      "useSectionEffectsContext must be used under StickyOffsetsContext.Provider"
    );
  }
  return context;
}

export const SectionEffectsProvider = ({
  children,
  effects,
}: {
  effects: EffectsData[];
  children: React.ReactNode;
}) => {
  const [effectsData, setEffectsData] = useState<EffectsData[]>(effects);

  const setSectionEffectsData = () => {
    const elems = document?.querySelectorAll(`[data-height]`);

    const newEffectsData = [...effectsData].map((offset) => {
      const elem = Array.from(elems).find(
        (elem) => elem.getAttribute("data-sectionuuid") === offset.sectionUuid
      );

      if (elem) {
        offset.height = Number(elem.getAttribute("data-height")) ?? 0;
        offset.ref = elem as HTMLDivElement;
      }
      return offset;
    });

    setEffectsData(newEffectsData);
  };

  const getCalculatedEfectsData = useCallback(() => {
    let sumHeight = 0;
    const newEffectsData = [...effectsData].map((offset, idx, array) => {
      if (offset.sticky) {
        let offsetSum = 0;
        for (let i = 0; i < idx; i++) {
          if (array[i].sticky) {
            offsetSum += array[i].height || 0;
          }
        }
        offset.offset = offsetSum;
      }
      sumHeight += offset.height || 0;
      offset.sumHeight = sumHeight;

      return offset;
    });
    return newEffectsData;
  }, [effectsData]);

  return (
    <SectionEffectsContext.Provider
      value={{
        effectsData: getCalculatedEfectsData(),
        setSectionEffectsData,
        isAnySectionSticky: effectsData.some((offset) => offset.sticky),
      }}
    >
      {children}
    </SectionEffectsContext.Provider>
  );
};
