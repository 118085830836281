import React, { useContext } from "react";
import { IPortalBox, TRegularBoxType, TSystemBoxType } from "@ecp-pageTypes";
import { PortalPath } from "@ecp-boxes/shared/portalPath/portalPath";

type BoxContextType<T extends IPortalBox> = {
  messages: T["messages"];
  settings: T["settings"];
  boxType?: TSystemBoxType | TRegularBoxType;
  boxData: IPortalBox<T>;
  isEditMode?: boolean;
  path?: PortalPath;
  publicationRef?: React.RefObject<HTMLDivElement>;
  pathExtraSettings?: string;
};
export const BoxContext =
  React.createContext<BoxContextType<IPortalBox> | null>(null);

export function useBoxContext<T extends IPortalBox>() {
  //@ts-ignore
  const context = useContext<BoxContextType<T> | null>(BoxContext);

  if (!context) {
    throw new Error("useBoxContext must be used under BoxContext.Provider");
  }

  return context;
}
