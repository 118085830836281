import { css } from "styled-components";
import { IDisplaySettingsSectionPopup } from "@ecp-redux/dto/themeSettings/StyledSection.types";
import {
  HoverEffects,
  IBackground,
  IBorder,
  IBorder_v2,
  IBorder_v3,
  IElementImage,
  IIcon,
} from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import { TRowHeight } from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import {
  AlignmentHorizontalOption,
  AlignmentVerticalOption,
  CloseButtonPositionOption,
  FontWeightOptionsCombine,
  FontWeightOptionsDeprecated,
  FontWeightOptionsLongListOfWeights,
  IGlobalButtonObject,
  IGlobalColorStyles,
  IGlobalLinkObject,
  IGlobalPaletteColor,
  ISpacing,
  IThemeState,
  OutlineOptions,
  RowHeightOption,
  SettingPositionOptions,
  TColorReadoutValue,
  TLinkId,
  TLinkReadoutValue,
  TLinkSet,
  TRowWidth,
  TRowWidthOption,
  TTooltipId,
  TTooltipReadoutValue,
  TTooltipSet,
  TTypoId,
  TTypoReadoutValue,
  TTypoSet,
  TUrl,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { imgResizer } from "../global/components/ImageWrapper/ImageWrapper.methods";
import { isClientSide, isPortalSide, noEmptyValue } from "../helpers/helpers";
import {
  convertColorIdToHex,
  convertLinkIdToValues,
  convertTooltipIdToValues,
  convertTypoIdToValues,
  getOpacity,
  isLinkId,
} from "./settingsPatterns.methods";
import { useRowPopup } from "../structure/RowPopup/useRowPopup";
import { convertToRatioFormat } from "../boxes/BoxVideo/BoxVideo.methods";
import { MOBILE_BREAKPOINT } from "../global/global";
import {
  OrientationOption,
  RatioOption,
} from "@ecp-boxes/boxes/BoxVideo/BoxVideo.types";

export const chooseVerticalAlignValue = (
  v: AlignmentVerticalOption
): "start" | "center" | "end" => {
  switch (v) {
    case AlignmentVerticalOption.TOP:
      return "start";
    case AlignmentVerticalOption.BOTTOM:
      return "end";
    default:
      return "center";
  }
};

export const chooseHorizontalAlignValue = (
  align: AlignmentHorizontalOption
) => {
  switch (align) {
    case AlignmentHorizontalOption.LEFT:
      return "start";
    case AlignmentHorizontalOption.RIGHT:
      return "end";
    default:
      return "center";
  }
};

export const alignmentOptionsToMarginValue = (
  marginType: "right" | "left",
  alignment: AlignmentHorizontalOption,
  margin: ISpacing
): string => {
  if (marginType === "right")
    return alignment === AlignmentHorizontalOption.LEFT ||
      alignment === AlignmentHorizontalOption.CENTER
      ? "auto"
      : `${margin.right}px`;
  return alignment === AlignmentHorizontalOption.RIGHT ||
    alignment === AlignmentHorizontalOption.CENTER
    ? "auto"
    : `${margin.left}px`;
};

export const alignmentOptionsToValue = (
  alignment: AlignmentHorizontalOption | AlignmentVerticalOption
) => {
  if (
    alignment === AlignmentVerticalOption.BOTTOM ||
    alignment === AlignmentHorizontalOption.RIGHT
  ) {
    return "flex-end";
  }

  if (
    alignment === AlignmentVerticalOption.TOP ||
    alignment === AlignmentHorizontalOption.LEFT
  ) {
    return "flex-start";
  }

  return alignment;
};

export const alignmentsOptionsToViewport = (
  $settings: IDisplaySettingsSectionPopup
) => {
  const isPortal = isPortalSide();
  const { isEditMode } = useRowPopup();
  const halfOfMargin = !isPortal && isEditMode ? 36 / 2 : 0;
  const {
    alignment: { horizontal, vertical },
    verticalOffset = 0,
    horizontalOffset = 0,
  } = $settings;

  const horizontalPosition = {
    [AlignmentHorizontalOption.LEFT]: `left: 0;`,
    [AlignmentHorizontalOption.CENTER]: `left: 50%;`,
    [AlignmentHorizontalOption.RIGHT]: `right: 0;`,
  };

  const verticalPosition = {
    [AlignmentVerticalOption.TOP]: `top: 0;`,
    [AlignmentVerticalOption.CENTER]: `top: 50%;`,
    [AlignmentVerticalOption.BOTTOM]: `bottom: 0;`,
  };

  const horizontalTransform = {
    [AlignmentHorizontalOption.LEFT]: `translateX(${horizontalOffset}px)`,
    [AlignmentHorizontalOption.CENTER]: `translateX(calc(-50% + ${horizontalOffset}px))`,
    [AlignmentHorizontalOption.RIGHT]: `translateX(${horizontalOffset}px)`,
  };

  const verticalTransform = {
    [AlignmentVerticalOption.TOP]: `translateY(${verticalOffset}px)`,
    [AlignmentVerticalOption.CENTER]: `translateY(calc(-50% + ${verticalOffset - halfOfMargin}px))`,
    [AlignmentVerticalOption.BOTTOM]: `translateY(${verticalOffset}px)`,
  };

  return css`
    ${horizontalPosition[horizontal]}
    ${verticalPosition[vertical]}
    transform: ${horizontalTransform[horizontal]} ${verticalTransform[
      vertical
    ]};
  `;
};

export const closeButtonAlignment = (
  $settings: IDisplaySettingsSectionPopup
) => {
  const {
    closeButtonAlignment,
    closeButtonHorizontalOffset,
    closeButtonVerticalOffset,
    closeButtonPosition,
  } = $settings;

  const multiplyFactor =
    closeButtonPosition === CloseButtonPositionOption.INSIDE ? 1 : -1;

  const horizontalAlignment = {
    [SettingPositionOptions.LEFT]: "left: 0;",
    [SettingPositionOptions.RIGHT]: "right: 0",
  };

  const transformAlignment = {
    [AlignmentHorizontalOption.LEFT]: `transform: translate(calc(-50% + ${
      closeButtonHorizontalOffset * multiplyFactor
    }px), calc(-50% + ${closeButtonVerticalOffset * multiplyFactor}px));`,
    [AlignmentHorizontalOption.RIGHT]: `transform: translate(calc(50% - ${
      closeButtonHorizontalOffset * multiplyFactor
    }px), calc(-50% + ${closeButtonVerticalOffset * multiplyFactor}px));`,
  };

  return css`
    ${horizontalAlignment[closeButtonAlignment]};
    ${transformAlignment[closeButtonAlignment]};
  `;
};

export const fontWeightOptionToValue = (
  weight: FontWeightOptionsLongListOfWeights | FontWeightOptionsDeprecated
) => {
  const arr = [100, 200, 300, 400, 500, 600, 700, 800, 900, 300, 400, 600];
  return arr[Object.keys(FontWeightOptionsCombine).indexOf(weight)];
};

export const convertToLongListOfSizes = (
  weight: FontWeightOptionsLongListOfWeights | FontWeightOptionsDeprecated
) => {
  if (weight in FontWeightOptionsLongListOfWeights) {
    return weight;
  } else {
    switch (weight) {
      case FontWeightOptionsDeprecated.REGULAR:
        return FontWeightOptionsLongListOfWeights.Weight300;
      case FontWeightOptionsDeprecated.SEMIBOLD:
        return FontWeightOptionsLongListOfWeights.Weight400;
      case FontWeightOptionsDeprecated.BOLD:
        return FontWeightOptionsLongListOfWeights.Weight600;
    }
    return;
  }
};

const backgroundStyles = (background: IBackground, theme: IThemeState) =>
  background.backgroundUrl.length > 0
    ? `url(${background.backgroundUrl}) ${
        background.fit
          ? `${background.alignment.horizontal} ${background.alignment.vertical} / contain`
          : `${background.alignment.vertical} center / cover`
      } no-repeat`
    : `${convertColorIdToHex(background.color, theme.colorPalette)}`;

const createPhotoUrl = (image: IElementImage): TUrl => {
  const { parallax, backgroundUrl, width } = image;
  return parallax ? backgroundUrl : imgResizer(backgroundUrl, width);
};

const imageStyles = (image: IElementImage) =>
  image.backgroundUrl.length > 0
    ? `url(${createPhotoUrl(image)}) ${
        image.fit
          ? `${image.alignment.horizontal} ${image.alignment.vertical} / contain`
          : `${image.alignment.vertical} center / cover`
      } no-repeat ${!image.fit && image.parallax ? "fixed" : "scroll"}`
    : "";

const hoverEffect = (effect: HoverEffects) => {
  switch (effect) {
    case HoverEffects.ZOOMIN:
      return `
	      transform: scale(1);
	      transition: transform .3s ease-in-out;
        &:hover{
	        transform: scale(1.2);
        }
      `;
    case HoverEffects.ZOOMOUT:
      return `
	      transform: scale(1);
	      transition: transform .3s ease-in-out;
        &:hover{
	        transform: scale(0.8);
        }
      `;
    case HoverEffects.BLUR:
      return `
	      filter: blur(0);
	      transition: filter .3s ease-in-out;
        &:hover{
	        filter: blur(3px);
        }
      `;
    case HoverEffects.GREY:
      return `
	      filter: grayscale(0);
	      transition: filter .3s ease-in-out;
        &:hover{
	        filter: grayscale(100%);
        }
      `;
    case HoverEffects.OPACITY:
      return `
        opacity: 1;
	      transition: opacity .3s ease-in-out;
        &:hover{
          opacity: .5;
        }
      `;
    case HoverEffects.FLASHING:
      return `
        @keyframes flash {
        	0% {
        		opacity: .4;
        	}
        	100% {
        		opacity: 1;
        	}
        }
        
        &:hover{
          opacity: 1;
	        animation: flash 1.5s;
        }
      `;
    default:
      return "";
  }
};

export const ratioStyle = (
  effect: RatioOption | string,
  orientation: string
) => {
  const convertedEffect = convertToRatioFormat(effect);

  const ratio = convertedEffect.replace(":", "/");

  if (!ratio) return "";

  return orientation === OrientationOption.HORIZONTAL
    ? ratio
    : ratio.split("/").reverse().join("/");
};

const buttonStyles = {
  borderWidth: (
    widthV2: IGlobalButtonObject["border"]["widthV2"],
    width: IGlobalButtonObject["border"]["width"]
  ) => {
    if (typeof width === "number" && widthV2 === null) {
      return css`
        border-width: ${width}px;
      `;
    }
    const { top, right, bottom, left } = widthV2 ?? {};
    return css`
      border-top-width: ${top}px;
      border-right-width: ${right}px;
      border-bottom-width: ${bottom}px;
      border-left-width: ${left}px;
    `;
  },
  borderRadius: (
    radiusV2: IGlobalButtonObject["border"]["radiusV2"],
    radius: IGlobalButtonObject["border"]["radius"]
  ) => {
    if (typeof radius === "number" && radiusV2 === null) {
      return css`
        border-radius: ${radius}px;
      `;
    }
    const { bottomLeft, bottomRight, topLeft, topRight } = radiusV2 ?? {};
    return css`
      border-top-left-radius: ${topLeft}px;
      border-top-right-radius: ${topRight}px;
      border-bottom-left-radius: ${bottomLeft}px;
      border-bottom-right-radius: ${bottomRight}px;
    `;
  },
  borderColor: (
    border: IGlobalButtonObject["border"],
    theme: IThemeState
  ) => css`
    border-color: ${convertColorIdToHex(border.color, theme.colorPalette)};
    ${border.hoverColor != null &&
    css`
      &:hover {
        border-color: ${convertColorIdToHex(
          border.hoverColor,
          theme.colorPalette
        )};
      }
    `}

    ${border.activeColor != null &&
    css`
      &:active {
        border-color: ${convertColorIdToHex(
          border.activeColor,
          theme.colorPalette
        )};
      }
    `}

  ${border.disabledColor != null &&
    css`
      &:disabled {
        border-color: ${convertColorIdToHex(
          border.disabledColor,
          theme.colorPalette
        )};
      }
    `}
  `,
  icon: (icon: IIcon) =>
    noEmptyValue(icon.iconUrl) &&
    css`
      & {
        display: flex;
        gap: ${icon.spacing}px;
        align-items: center;
      }

      & > i,
      & > i {
        order: ${icon.position === "LEFT" ? 0 : 1};
      }

      & > i:before,
      & > i:before {
        display: block;
        content: "";
        background: url(${icon.iconUrl});
        background-repeat: no-repeat;
        background-size: 100%;
        height: ${icon.size}px;
        width: ${icon.size}px;
      }
    `,
};

const fontStyles = (
  font: TTypoReadoutValue | TTypoId,
  typography: TTypoSet
) => {
  const fontValue = convertTypoIdToValues(font, typography);

  const fontNameSeparator = fontValue?.family.split("_")?.join(" ");
  return `
    font-family: "${fontNameSeparator}";
    font-weight: ${fontWeightOptionToValue(fontValue.weight)};
    font-size: ${fontValue.size / 16}rem;
    line-height: ${fontValue.lineHeight}px;
    letter-spacing: ${fontValue.letterSpacing}px;
  `;
};

const getGlobalLinkObject = (
  link: TLinkReadoutValue | TLinkId,
  globalLinks: TLinkSet
): IGlobalLinkObject => {
  if (isLinkId(link)) {
    return globalLinks.find((style) => style.id === link) ?? globalLinks[0];
  }
  if (link.id !== "custom") {
    return globalLinks.find((style) => style.id === link.id) ?? globalLinks[0];
  }
  return link.custom;
};

const linkStyle = (
  link: TLinkReadoutValue | TLinkId,
  globalLinks: TLinkSet,
  colorPalette: IGlobalPaletteColor[],
  isActive?: boolean
) => {
  const linkValue = convertLinkIdToValues(link, globalLinks).text;
  const globalLinkObject = getGlobalLinkObject(link, globalLinks);

  const cursorStyle = `
  cursor: pointer;
    :disabled {
      cursor: default;
    }`;
  return (
    cursorStyle +
    color(linkValue, colorPalette, isActive) +
    textDecoration(globalLinkObject, isActive)
  );
};

const tooltipStyle = (
  tooltip: TTooltipReadoutValue | TTooltipId,
  tooltips: TTooltipSet,
  colorPalette: IGlobalPaletteColor[],
  typography: TTypoSet
) => {
  const {
    borderRadius,
    horizontalPadding,
    verticalPadding,
    maximumWidth,
    background,
    font,
    text,
  } = convertTooltipIdToValues(tooltip, tooltips);

  return (
    color(text, colorPalette) +
    backgroundColor(background, colorPalette) +
    fontStyles(font, typography) +
    composeCss.borderRadius(borderRadius) +
    `padding: ` +
    composeCss.padding({
      left: horizontalPadding,
      right: horizontalPadding,
      bottom: verticalPadding,
      top: verticalPadding,
    }) +
    `; 
    max-width: ${maximumWidth}px;`
  );
};

const textDecoration = (linkStyles: IGlobalLinkObject, isActive?: boolean) => {
  return `
    ${
      (linkStyles?.linkUnderline && !isActive) ||
      (linkStyles?.linkActiveUnderline && isActive)
        ? "text-decoration: underline;"
        : ""
    }
    ${
      linkStyles?.linkHoverUnderline
        ? `
          &:hover {
            text-decoration: ${
              linkStyles.linkHoverUnderline ? "underline" : "none"
            };
          }
          `
        : ""
    }
    
    ${
      linkStyles?.linkActiveUnderline
        ? `
          &:active {
            text-decoration: ${
              linkStyles.linkActiveUnderline ? "underline" : "none"
            };
          }
          `
        : ""
    }
`;
};

const backgroundColor = (
  colors: Partial<IGlobalColorStyles>,
  colorPalette: IGlobalPaletteColor[]
) =>
  `${
    colors.color != null
      ? `background-color: ${convertColorIdToHex(colors.color, colorPalette)};`
      : ""
  };`;

const color = (
  colors: Partial<IGlobalColorStyles>,
  colorPalette: IGlobalPaletteColor[],
  isActive?: boolean
) => `
      ${
        colors.color != null
          ? `color: ${convertColorIdToHex(
              isActive && colors.activeColor != null
                ? colors.activeColor
                : colors.color,
              colorPalette
            )};`
          : ""
      }
      ${
        colors.hoverColor != null
          ? `
            &:hover {
                color: ${convertColorIdToHex(colors.hoverColor, colorPalette)};
            }
            `
          : ""
      }
      
      ${
        colors.activeColor != null
          ? `
            &:active {
                color: ${convertColorIdToHex(colors.activeColor, colorPalette)};
            }
            `
          : ""
      }

      ${
        colors.disabledColor != null
          ? `
            &:disabled {
                color: ${convertColorIdToHex(
                  colors.disabledColor,
                  colorPalette
                )};
            }
            `
          : ""
      };
  `;

function isRowWidth(widthV: string | number | TRowWidth): widthV is TRowWidth {
  return (widthV as TRowWidth).selectedOption !== undefined;
}

const responsiveClamp = (
  targetValue: number,
  minViewportWidth: number,
  maxViewportWidth: number
) => {
  const maxValue = targetValue < 10 ? targetValue : 10;
  const scalingFactor = targetValue / (maxViewportWidth - minViewportWidth);

  return css`
    max(
      ${maxValue}px,
      clamp(
        0px,
        calc((100vw - ${minViewportWidth}px) * ${scalingFactor}),
        ${targetValue}px
      )
    );
  `;
};

const width = (
  widthV: string | number | TRowWidth,
  popupEnabled?: boolean,
  theme?: IThemeState
) => {
  if (popupEnabled) return "100%";
  if (widthV === undefined) return;

  if (isRowWidth(widthV)) {
    const maxViewportWidth = theme?.stylePages.pages.maxWidth ?? 1920;
    return widthV.selectedOption === TRowWidthOption.MARGIN
      ? css`
          margin-right: ${responsiveClamp(
            widthV.margin.right,
            1440,
            maxViewportWidth
          )};
          margin-left: ${responsiveClamp(
            widthV.margin.left,
            1440,
            maxViewportWidth
          )};
        `
      : css`
          margin: 0 auto;
          width: ${widthV.percent}%;
        `;
  }
  return widthV !== "auto" ? `${widthV}px` : widthV;
};

const backgroundWithOpacity = (
  backgroundSett: IBackground,
  theme: IThemeState
) => {
  return css`
    position: relative;
    &:before {
      content: "";
      background: ${composeCss.background(backgroundSett, theme)};
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      opacity: ${getOpacity(backgroundSett)};
    }
  `;
};

const lineClamp = (lines: number) => css`
  display: -webkit-box;
  -webkit-line-clamp: ${lines};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

/**
 * Object with methods for each setting. Returns CSS based on valid keys and values.
 *
 * @remarks Boxes/Elements that have unique settings should have their own nested object. Only modify top level properties, if the change is global!
 *
 * @example
 * ```
 * const composeCss = { margin: `CSS`, sliderSettings: { margin: `CSS` } }
 * ```
 */
const INITIAL_FONT_SIZE = isClientSide()
  ? parseFloat(getComputedStyle(document.body).fontSize)
  : 16;
const composeCss = {
  margin: (margin: ISpacing) =>
    `${margin.top}px ${margin.right}px ${margin.bottom}px ${margin.left}px`,
  padding: (padding: ISpacing) => {
    const paddingLeftInRem = padding.left / INITIAL_FONT_SIZE;
    const paddingRightInRem = padding.right / INITIAL_FONT_SIZE;
    return `${padding.top}px ${paddingRightInRem}rem ${padding.bottom}px ${paddingLeftInRem}rem`;
  },
  clampPadding: (padding: ISpacing, theme: IThemeState) => {
    return css`
      padding-left: ${responsiveClamp(
        padding.left,
        1440,
        theme.stylePages.pages.maxWidth
      )};

      @media (max-width: ${MOBILE_BREAKPOINT}px) {
        padding-left: ${responsiveClamp(padding.left, 0, MOBILE_BREAKPOINT)};
      }

      padding-right: ${responsiveClamp(
        padding.right,
        1440,
        theme.stylePages.pages.maxWidth
      )};

      @media (max-width: ${MOBILE_BREAKPOINT}px) {
        padding-right: ${responsiveClamp(padding.right, 0, MOBILE_BREAKPOINT)};
      }
      padding-top: ${padding.top}px;
      padding-bottom: ${padding.bottom}px;
    `;
  },
  textDecoration: (decoration: React.CSSProperties["textDecoration"]) => {
    if (decoration !== undefined) {
      return css`
        text-decoration: ${decoration};
      `;
    }
    return "";
  },
  border: (border: Partial<Omit<IBorder, "radius">>, theme: IThemeState) =>
    border?.style !== undefined
      ? border.style !== OutlineOptions.NONE
        ? `${border?.width ?? 0}px ${border?.style?.toLowerCase()} ${
            border?.color &&
            convertColorIdToHex(border.color, theme.colorPalette)
          }`
        : ""
      : `${border?.width ?? 0}px solid ${
          border?.color && convertColorIdToHex(border.color, theme.colorPalette)
        }`,
  borderWidth: (width: IBorder_v2["width"]) =>
    `
    border-width: ${width.top}px ${width.right}px ${width.bottom}px ${width.left}px;
    `,
  borderStyle: (style: IBorder_v2["style"]) =>
    `
    border-style: ${style.toLowerCase()};
    `,
  borderRadius: (radius: IBorder_v2["radius"]) =>
    `
    border-radius: ${radius}px;
    `,
  borderColor: (color: TColorReadoutValue, theme: IThemeState) =>
    `
    border-color: ${convertColorIdToHex(color, theme.colorPalette)};
    `,
  height: (height: number | string) =>
    height !== "auto" ? `${height}px` : height,
  minHeight: (minHeight: TRowHeight) =>
    minHeight.selectedOption === RowHeightOption.FIXED
      ? `min-height: ${minHeight.value}px;`
      : "",

  borderRadiusWithCorners: (radius: IBorder_v3["radius"]) => {
    return css`
      border-top-left-radius: ${radius.topLeft}px;
      border-top-right-radius: ${radius.topRight}px;
      border-bottom-left-radius: ${radius.bottomLeft}px;
      border-bottom-right-radius: ${radius.bottomRight}px;
    `;
  },
  width,
  background: backgroundStyles,
  font: fontStyles,
  color,
  buttonSettings: buttonStyles,
  imageSettings: imageStyles,
  hoverEffect,
  link: linkStyle,
  ratioStyle,
  tooltip: tooltipStyle,
  backgroundWithOpacity,
  lineClamp,
};

export default composeCss;
