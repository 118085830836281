import ReactPlayer from "react-player";
import { IPortalBox } from "@ecp-pageTypes";
import { GenericBoxData } from "@ecp-redux/dto/page/union.types";
import {
  ISpacing,
  TUrl,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import {
  IBoxBaseSettings,
  IElementTextContent,
  IElementVideo,
  IVideoSize,
} from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import { z } from "zod";
import { BoxVideoMessagesScheme } from "./BoxVideo.scheme";

export type IBoxVideoProps = IPortalBox<IBoxVideoData>;

type IBoxVideoData = GenericBoxData<
  IBoxVideoContent,
  IBoxVideoContentSettings,
  IBoxVideoDisplaySettings,
  IVideoMessages,
  Record<string, never>
>;

interface IPlaybackControls {
  isPlaying: boolean;
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  playbackRate: number;
  setPlaybackRate: React.Dispatch<React.SetStateAction<number>>;
  isMuted: boolean;
  setIsMuted: React.Dispatch<React.SetStateAction<boolean>>;
  setVolume: React.Dispatch<React.SetStateAction<number>>;
  volume: number;
}

export interface IControlBarProps {
  progress: number;
  playerRef: React.RefObject<ReactPlayer>;
  containerRef: React.RefObject<HTMLDivElement>;
  playbackControls: IPlaybackControls;
}

export enum RatioOption {
  ONE_TO_ONE = "1:1",
  THREE_TO_ONE = "3:1",
  THREE_TO_TWO = "3:2",
  FOUR_TO_THREE = "4:3",
  FIVE_TO_THREE = "5:3",
  FIVE_TO_FOUR = "5:4",
  SIXTEEN_TO_NINE = "16:9",
  SIXTEEN_TO_TEN = "16:10",
  TWENTY_ONE_TO_NINE = "21:9",
}

export enum OrientationOption {
  HORIZONTAL = "HORIZONTAL",
  VERTICAL = "VERTICAL",
}

export interface IBoxVideoContent {
  coverImage: TUrl;
  videoURL: TUrl;
}

export interface IBoxVideoContentSettings {
  title: IElementTextContent;
  video: IElementVideo;
}

export interface IBoxVideoDisplaySettings {
  padding: Pick<ISpacing, "top" | "bottom">;
  ratio: RatioOption;
  size: IVideoSize;
  orientation: OrientationOption;
}

type IVideoMessages = z.infer<typeof BoxVideoMessagesScheme>;

export type IBoxVideoSettings = IBoxBaseSettings<
  IBoxVideoDisplaySettings & IBoxVideoContentSettings
>;
