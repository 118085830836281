import * as wishList from "@ecp-redux/api/wishList";
import { TSrpProductType } from "@ecp-redux/dto/searchResults.types";
import { IBoxPhotoGalleryProps } from "../boxes/BoxPhotoGallery/BoxPhotoGallery.types";
import { IBoxProductSliderProps } from "../boxes/BoxProductSlider/BoxProductSlider.types";
import { BoxSearchResultsProps } from "../boxes/BoxSearchResults/BoxSearchResults.types";
import { IBoxCartStepOneProps } from "../boxes/Cart/BoxCartStepOne/BoxCartStepOne.types";
import { useOpenPortalSnackbar } from "../shared/components/Snackbar/Snackbar";
import { useBoxContext } from "../structure/Contexts/BoxContext";
import { IBoxCompareProps } from "@ecp-boxes/boxes/BoxCompare/BoxCompare.types";
import { IBoxListOfButtonsProps } from "@ecp-boxes/boxes/BoxListOfButtons/BoxListOfButtons.types";

export const useWishList = ({
  sku,
  type,
}: {
  sku: string;
  type: TSrpProductType;
}) => {
  const { messages, settings } = useBoxContext<
    IBoxCartStepOneProps &
      BoxSearchResultsProps &
      IBoxPhotoGalleryProps &
      IBoxProductSliderProps &
      IBoxCompareProps &
      IBoxListOfButtonsProps
  >();

  const [addToWishList] = wishList.usePostAddProductToWishListMutation();
  const [deleteFromWishList] = wishList.useDeleteProductFromWishListMutation();

  const { openPortalSnackbar } = useOpenPortalSnackbar();

  const handleAddToWishList = async () => {
    await addToWishList({
      sku,
      type,
    })
      .unwrap()
      .then(() => {
        openPortalSnackbar(
          settings.wishlist_success_snackbar,
          messages.add_to_wishlist_success_text
        );
      })
      .catch((e) => {
        if (e.status === 403) {
          openPortalSnackbar(
            settings.wishlist_error_snackbar,
            messages.wishlist_not_logged_error
          );
        } else {
          openPortalSnackbar(
            settings.wishlist_error_snackbar,
            messages.wishlist_error_text
          );
        }
      });
  };

  const handleRemoveFromWishList = async () => {
    await deleteFromWishList({ sku, type })
      .unwrap()
      .then(() => {
        openPortalSnackbar(
          settings.wishlist_success_snackbar,
          messages.delete_from_wishlist_success_text
        );
      })
      .catch((e) => {
        if (e.status === 403) {
          openPortalSnackbar(
            settings.wishlist_error_snackbar,
            messages.wishlist_not_logged_error
          );
        } else {
          openPortalSnackbar(
            settings.wishlist_error_snackbar,
            messages.wishlist_error_text
          );
        }
      });
  };

  return { handleAddToWishList, handleRemoveFromWishList };
};
