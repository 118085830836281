import { defaultTableCellPattern } from "../dto/themeSettings/GlobalTableCell.types";
import { ThemeSettingsSchemeBasic } from "../dto/themeSettings/themeSettings.schemes";
import {
  AlignmentHorizontalOption,
  AlignmentVerticalOption,
  COLOR_TRANSPARENT,
  FontFamilyOptions,
  FontWeightOptionsLongListOfWeights,
  IAdvanceMessages,
  IAdvanceSettings,
  IThemeState,
  IThemeStateMobile,
  OutlineOptions,
  SettingPositionOptions,
  SlideFromDirections,
  SnackbarAnimationType,
  TRowWidthOption,
} from "../dto/themeSettings/themeSettings.types";
import {
  defaultAccordionPattern,
  defaultDropdownPattern,
} from "../mocks/responseAPI/themeSettings";
import { API, api } from "./api";

export interface IGetThemeSettingsResponse {
  content: string;
  mobileContent: string;
  typedContent?: {
    typography: string;
    colorPalette: string;
    globalObjects: string;
    stylePages: string;
    advanceSettings: {
      messages: IAdvanceMessages;
      localeMessages: Record<string, IAdvanceMessages>;
      settings: IAdvanceSettings;
      customCss: string;
      customJs: string;
      trackingScript: string;
      allowedReferralList: string[];
    };
    systemPages: string;
  };
  typedMobileContent?: {
    typography: string;
    colorPalette: string;
    globalObjects: string;
    stylePages: string;
    advanceSettings: {
      messages: string;
      localeMessages: Record<string, IAdvanceMessages>;
      settings: string;
      customCss: string;
      customJs: string;
      trackingScript: string;
      allowedReferralList: string[];
    };
    systemPages: string;
  };
}

export interface ThemeStatePageWizard {
  basic: IThemeState;
  mobile: IThemeStateMobile | null;
}

export const initialThemeSettings: ThemeStatePageWizard = {
  basic: {
    typography: [
      {
        id: "typo$$1",
        typoStyleName: "Header",
        family: FontFamilyOptions.ROBOTO,
        weight: FontWeightOptionsLongListOfWeights.Weight300,
        size: 24,
        lineHeight: 10,
        letterSpacing: 1,
        inheritedFromBasic: false,
      },
      {
        id: "typo$$2",
        typoStyleName: "Secondary font",
        family: FontFamilyOptions.ROBOTO,
        weight: FontWeightOptionsLongListOfWeights.Weight300,
        size: 18,
        lineHeight: 10,
        letterSpacing: 1,
        inheritedFromBasic: false,
      },
      {
        id: "typo$$3",
        typoStyleName: "Body font",
        family: FontFamilyOptions.ROBOTO,
        weight: FontWeightOptionsLongListOfWeights.Weight300,
        size: 16,
        lineHeight: 10,
        letterSpacing: 1,
        inheritedFromBasic: false,
      },
    ],
    colorPalette: [
      {
        id: "color$$1",
        name: "Base Color",
        colorHex: "#000000",
      },
      {
        id: "color$$2",
        name: "Secondary Color",
        colorHex: "#3779BC",
      },
      {
        id: "color$$3",
        name: "Complementary Color",
        colorHex: "#AFCBE8",
      },
      {
        id: "color$$4",
        name: "None",
        colorHex: COLOR_TRANSPARENT,
      },
    ],
    globalObjects: {
      buttons: [
        {
          id: "button$$1",
          buttonStyleName: "Button1",
          horizontalPadding: 20,
          verticalPadding: 20,
          border: {
            radius: 20,
            radiusV2: {
              topLeft: 20,
              topRight: 20,
              bottomLeft: 20,
              bottomRight: 20,
            },
            width: 1,
            widthV2: { left: 1, right: 1, top: 1, bottom: 1 },
            color: { id: "color$$3", custom: "#000000" },
            hoverColor: { id: "color$$1", custom: "#000000" },
            activeColor: { id: "color$$1", custom: "#000000" },
            disabledColor: { id: "color$$1", custom: "#000000" },
          },
          font: {
            id: "typo$$3",
            custom: {
              family: FontFamilyOptions.ROBOTO,
              weight: FontWeightOptionsLongListOfWeights.Weight300,
              size: 24,
              lineHeight: 10,
              letterSpacing: 1,
            },
          },
          background: {
            color: { id: "color$$3", custom: "#000000" },
            hoverColor: { id: "color$$3", custom: "#000000" },
            activeColor: { id: "color$$3", custom: "#000000" },
            disabledColor: { id: "color$$3", custom: "#000000" },
          },
          text: {
            color: { id: "color$$3", custom: "#000000" },
            hoverColor: { id: "color$$3", custom: "#000000" },
            activeColor: { id: "color$$3", custom: "#000000" },
            disabledColor: { id: "color$$3", custom: "#000000" },
          },
          icon: {
            iconUrl: "/icon",
            position: SettingPositionOptions.LEFT,
            size: 16,
            spacing: 15,
            color: {
              id: "color$$1",
              custom: "#000000",
            },
            hoverColor: {
              id: "color$$1",
              custom: "#000000",
            },
            activeColor: {
              id: "color$$1",
              custom: "#000000",
            },
            disabledColor: {
              id: "color$$1",
              custom: "#000000",
            },
          },
        },
        {
          id: "button$$2",
          buttonStyleName: "Button2",
          horizontalPadding: 20,
          verticalPadding: 20,
          border: {
            radius: 50,
            radiusV2: {
              topLeft: 50,
              topRight: 50,
              bottomLeft: 50,
              bottomRight: 50,
            },
            widthV2: { left: 1, right: 1, top: 1, bottom: 1 },
            width: 1,
            color: { id: "color$$3", custom: "#000000" },
            hoverColor: { id: "color$$1", custom: "#000000" },
            activeColor: { id: "color$$1", custom: "#000000" },
            disabledColor: { id: "color$$1", custom: "#000000" },
          },
          font: {
            id: "typo$$3",
            custom: {
              family: FontFamilyOptions.ROBOTO,
              weight: FontWeightOptionsLongListOfWeights.Weight300,
              size: 24,
              lineHeight: 10,
              letterSpacing: 1,
            },
          },
          background: {
            color: { id: "color$$3", custom: "#000000" },
            hoverColor: { id: "color$$3", custom: "#000000" },
            activeColor: { id: "color$$3", custom: "#000000" },
            disabledColor: { id: "color$$3", custom: "#000000" },
          },
          text: {
            color: { id: "color$$3", custom: "#000000" },
            hoverColor: { id: "color$$3", custom: "#000000" },
            activeColor: { id: "color$$3", custom: "#000000" },
            disabledColor: { id: "color$$3", custom: "#000000" },
          },
          icon: {
            iconUrl: "/icon",
            position: SettingPositionOptions.LEFT,
            size: 16,
            spacing: 15,
            color: {
              id: "color$$1",
              custom: "#000000",
            },
            hoverColor: {
              id: "color$$1",
              custom: "#000000",
            },
            activeColor: {
              id: "color$$1",
              custom: "#000000",
            },
            disabledColor: {
              id: "color$$1",
              custom: "#000000",
            },
          },
        },
        {
          id: "button$$3",
          buttonStyleName: "Button3",
          horizontalPadding: 20,
          verticalPadding: 20,
          border: {
            radius: 50,
            radiusV2: {
              topLeft: 50,
              topRight: 50,
              bottomLeft: 50,
              bottomRight: 50,
            },
            width: 1,
            widthV2: { left: 1, right: 1, top: 1, bottom: 1 },
            color: { id: "color$$3", custom: "#000000" },
            hoverColor: { id: "color$$1", custom: "#000000" },
            activeColor: { id: "color$$1", custom: "#000000" },
            disabledColor: { id: "color$$1", custom: "#000000" },
          },
          font: {
            id: "typo$$3",
            custom: {
              family: FontFamilyOptions.ROBOTO,
              weight: FontWeightOptionsLongListOfWeights.Weight300,
              size: 24,
              lineHeight: 10,
              letterSpacing: 1,
            },
          },
          background: {
            color: { id: "color$$3", custom: "#000000" },
            hoverColor: { id: "color$$3", custom: "#000000" },
            activeColor: { id: "color$$3", custom: "#000000" },
            disabledColor: { id: "color$$3", custom: "#000000" },
          },
          text: {
            color: { id: "color$$3", custom: "#000000" },
            hoverColor: { id: "color$$3", custom: "#000000" },
            activeColor: { id: "color$$3", custom: "#000000" },
            disabledColor: { id: "color$$3", custom: "#000000" },
          },
          icon: {
            iconUrl: "",
            position: SettingPositionOptions.LEFT,
            size: 16,
            spacing: 15,
            color: {
              id: "color$$1",
              custom: "#000000",
            },
            hoverColor: {
              id: "color$$1",
              custom: "#000000",
            },
            activeColor: {
              id: "color$$1",
              custom: "#000000",
            },
            disabledColor: {
              id: "color$$1",
              custom: "#000000",
            },
          },
        },
      ],
      inputs: [
        {
          id: "input$$1",
          inputStyleName: "Input1",
          width: 250,
          height: 40,
          border: {
            radius: 20,
            width: { left: 1, right: 1, top: 1, bottom: 1 },
            style: OutlineOptions.SOLID,
            color: { id: "color$$3", custom: "#000000" },
          },
          hoverBorder: {
            radius: 20,
            width: { left: 1, right: 1, top: 1, bottom: 1 },
            style: OutlineOptions.SOLID,
            color: { id: "color$$1", custom: "#000000" },
          },
          activeBorder: {
            radius: 20,
            width: { left: 1, right: 1, top: 1, bottom: 1 },
            style: OutlineOptions.SOLID,
            color: { id: "color$$1", custom: "#000000" },
          },
          disabledBorder: {
            radius: 20,
            width: { left: 1, right: 1, top: 1, bottom: 1 },
            style: OutlineOptions.SOLID,
            color: { id: "color$$1", custom: "#000000" },
          },
          errorBorder: {
            radius: 20,
            width: { left: 1, right: 1, top: 1, bottom: 1 },
            style: OutlineOptions.SOLID,
            color: { id: "color$$1", custom: "#000000" },
          },
          background: {
            color: { id: "color$$4", custom: "#000000" },
            hoverColor: { id: "color$$4", custom: "#000000" },
            activeColor: { id: "color$$4", custom: "#000000" },
            disabledColor: { id: "color$$4", custom: "#000000" },
          },
          font: {
            input: {
              id: "typo$$1",
              custom: {
                family: FontFamilyOptions.ROBOTO,
                weight: FontWeightOptionsLongListOfWeights.Weight300,
                size: 24,
                lineHeight: 10,
                letterSpacing: 1,
              },
            },
            label: {
              id: "typo$$1",
              custom: {
                family: FontFamilyOptions.ROBOTO,
                weight: FontWeightOptionsLongListOfWeights.Weight300,
                size: 24,
                lineHeight: 10,
                letterSpacing: 1,
              },
            },
            placeholder: {
              id: "typo$$3",
              custom: {
                family: FontFamilyOptions.ROBOTO,
                weight: FontWeightOptionsLongListOfWeights.Weight300,
                size: 24,
                lineHeight: 10,
                letterSpacing: 1,
              },
            },
            error: {
              id: "typo$$3",
              custom: {
                family: FontFamilyOptions.ROBOTO,
                weight: FontWeightOptionsLongListOfWeights.Weight300,
                size: 24,
                lineHeight: 10,
                letterSpacing: 1,
              },
            },
          },
          text: {
            color: { id: "color$$3", custom: "#000000" },
            hoverColor: { id: "color$$1", custom: "#000000" },
            activeColor: { id: "color$$1", custom: "#000000" },
            disabledColor: { id: "color$$1", custom: "#000000" },
            errorColor: { id: "color$$1", custom: "#000000" },
            placeholderColor: { id: "color$$3", custom: "#000000" },
            labelColor: { id: "color$$1", custom: "#000000" },
          },
          floatingLabel: false,
        },
        {
          id: "input$$2",
          inputStyleName: "Input2",
          width: 250,
          height: 40,
          border: {
            radius: 20,
            width: { left: 1, right: 1, top: 1, bottom: 1 },
            style: OutlineOptions.SOLID,
            color: { id: "color$$3", custom: "#000000" },
          },
          hoverBorder: {
            radius: 20,
            width: { left: 1, right: 1, top: 1, bottom: 1 },
            style: OutlineOptions.SOLID,
            color: { id: "color$$1", custom: "#000000" },
          },
          activeBorder: {
            radius: 20,
            width: { left: 1, right: 1, top: 1, bottom: 1 },
            style: OutlineOptions.SOLID,
            color: { id: "color$$1", custom: "#000000" },
          },
          disabledBorder: {
            radius: 20,
            width: { left: 1, right: 1, top: 1, bottom: 1 },
            style: OutlineOptions.SOLID,
            color: { id: "color$$1", custom: "#000000" },
          },
          errorBorder: {
            radius: 20,
            width: { left: 1, right: 1, top: 1, bottom: 1 },
            style: OutlineOptions.SOLID,
            color: { id: "color$$1", custom: "#000000" },
          },
          background: {
            color: { id: "color$$4", custom: "#000000" },
            hoverColor: { id: "color$$4", custom: "#000000" },
            activeColor: { id: "color$$4", custom: "#000000" },
            disabledColor: { id: "color$$4", custom: "#000000" },
          },
          font: {
            input: {
              id: "typo$$1",
              custom: {
                family: FontFamilyOptions.ROBOTO,
                weight: FontWeightOptionsLongListOfWeights.Weight300,
                size: 24,
                lineHeight: 10,
                letterSpacing: 1,
              },
            },
            label: {
              id: "typo$$1",
              custom: {
                family: FontFamilyOptions.ROBOTO,
                weight: FontWeightOptionsLongListOfWeights.Weight300,
                size: 24,
                lineHeight: 10,
                letterSpacing: 1,
              },
            },
            placeholder: {
              id: "typo$$3",
              custom: {
                family: FontFamilyOptions.ROBOTO,
                weight: FontWeightOptionsLongListOfWeights.Weight300,
                size: 24,
                lineHeight: 10,
                letterSpacing: 1,
              },
            },
            error: {
              id: "typo$$3",
              custom: {
                family: FontFamilyOptions.ROBOTO,
                weight: FontWeightOptionsLongListOfWeights.Weight300,
                size: 24,
                lineHeight: 10,
                letterSpacing: 1,
              },
            },
          },
          text: {
            color: { id: "color$$3", custom: "#000000" },
            hoverColor: { id: "color$$1", custom: "#000000" },
            activeColor: { id: "color$$1", custom: "#000000" },
            disabledColor: { id: "color$$1", custom: "#000000" },
            errorColor: { id: "color$$1", custom: "#000000" },
            placeholderColor: { id: "color$$3", custom: "#000000" },
            labelColor: { id: "color$$1", custom: "#000000" },
          },
          floatingLabel: false,
        },
        {
          id: "input$$3",
          inputStyleName: "Input3",
          width: 250,
          height: 40,
          border: {
            radius: 20,
            width: { left: 1, right: 1, top: 1, bottom: 1 },
            style: OutlineOptions.SOLID,
            color: { id: "color$$3", custom: "#000000" },
          },
          hoverBorder: {
            radius: 20,
            width: { left: 1, right: 1, top: 1, bottom: 1 },
            style: OutlineOptions.SOLID,
            color: { id: "color$$1", custom: "#000000" },
          },
          activeBorder: {
            radius: 20,
            width: { left: 1, right: 1, top: 1, bottom: 1 },
            style: OutlineOptions.SOLID,
            color: { id: "color$$1", custom: "#000000" },
          },
          disabledBorder: {
            radius: 20,
            width: { left: 1, right: 1, top: 1, bottom: 1 },
            style: OutlineOptions.SOLID,
            color: { id: "color$$1", custom: "#000000" },
          },
          errorBorder: {
            radius: 20,
            width: { left: 1, right: 1, top: 1, bottom: 1 },
            style: OutlineOptions.SOLID,
            color: { id: "color$$1", custom: "#000000" },
          },
          background: {
            color: { id: "color$$4", custom: "#000000" },
            hoverColor: { id: "color$$4", custom: "#000000" },
            activeColor: { id: "color$$4", custom: "#000000" },
            disabledColor: { id: "color$$4", custom: "#000000" },
          },
          font: {
            input: {
              id: "typo$$1",
              custom: {
                family: FontFamilyOptions.ROBOTO,
                weight: FontWeightOptionsLongListOfWeights.Weight300,
                size: 24,
                lineHeight: 10,
                letterSpacing: 1,
              },
            },
            label: {
              id: "typo$$1",
              custom: {
                family: FontFamilyOptions.ROBOTO,
                weight: FontWeightOptionsLongListOfWeights.Weight300,
                size: 24,
                lineHeight: 10,
                letterSpacing: 1,
              },
            },
            placeholder: {
              id: "typo$$3",
              custom: {
                family: FontFamilyOptions.ROBOTO,
                weight: FontWeightOptionsLongListOfWeights.Weight300,
                size: 24,
                lineHeight: 10,
                letterSpacing: 1,
              },
            },
            error: {
              id: "typo$$3",
              custom: {
                family: FontFamilyOptions.ROBOTO,
                weight: FontWeightOptionsLongListOfWeights.Weight300,
                size: 24,
                lineHeight: 10,
                letterSpacing: 1,
              },
            },
          },
          text: {
            color: { id: "color$$3", custom: "#000000" },
            hoverColor: { id: "color$$1", custom: "#000000" },
            activeColor: { id: "color$$1", custom: "#000000" },
            disabledColor: { id: "color$$1", custom: "#000000" },
            errorColor: { id: "color$$1", custom: "#000000" },
            placeholderColor: { id: "color$$3", custom: "#000000" },
            labelColor: { id: "color$$1", custom: "#000000" },
          },
          floatingLabel: false,
        },
      ],
      links: [
        {
          id: "link$$1",
          linkStyleName: "Link1",
          text: {
            color: { id: "color$$2", custom: "#000000" },
            hoverColor: { id: "color$$3", custom: "#000000" },
            activeColor: { id: "color$$2", custom: "#000000" },
          },
        },
        {
          id: "link$$2",
          linkStyleName: "Link2",
          text: {
            color: { id: "color$$2", custom: "#000000" },
            hoverColor: { id: "color$$3", custom: "#000000" },
            activeColor: { id: "color$$2", custom: "#000000" },
          },
        },
        {
          id: "link$$3",
          linkStyleName: "Link3",
          text: {
            color: { id: "color$$2", custom: "#000000" },
            hoverColor: { id: "color$$3", custom: "#000000" },
            activeColor: { id: "color$$2", custom: "#000000" },
          },
        },
      ],
      tooltips: [
        {
          id: "tooltip$$1",
          tooltipStyleName: "Tooltip 1",
          background: {
            color: { id: "color$$4", custom: "#000000" },
          },
          text: {
            color: { id: "color$$2", custom: "#000000" },
          },
          font: {
            id: "typo$$3",
            custom: {
              family: FontFamilyOptions.ROBOTO,
              weight: FontWeightOptionsLongListOfWeights.Weight300,
              size: 24,
              lineHeight: 10,
              letterSpacing: 1,
            },
          },
          arrow: true,
          verticalPadding: 10,
          horizontalPadding: 10,
          maximumWidth: 10,
          borderRadius: 10,
        },
        {
          id: "tooltip$$2",
          tooltipStyleName: "Tooltip 2",
          background: {
            color: { id: "color$$4", custom: "#000000" },
          },
          text: {
            color: { id: "color$$2", custom: "#000000" },
          },
          font: {
            id: "typo$$3",
            custom: {
              family: FontFamilyOptions.ROBOTO,
              weight: FontWeightOptionsLongListOfWeights.Weight300,
              size: 24,
              lineHeight: 10,
              letterSpacing: 1,
            },
          },
          arrow: true,
          verticalPadding: 10,
          horizontalPadding: 10,
          maximumWidth: 10,
          borderRadius: 10,
        },
        {
          id: "tooltip$$3",
          tooltipStyleName: "Tooltip 3",
          background: {
            color: { id: "color$$4", custom: "#000000" },
          },
          text: {
            color: { id: "color$$2", custom: "#000000" },
          },
          font: {
            id: "typo$$3",
            custom: {
              family: FontFamilyOptions.ROBOTO,
              weight: FontWeightOptionsLongListOfWeights.Weight300,
              size: 24,
              lineHeight: 10,
              letterSpacing: 1,
            },
          },
          arrow: true,
          verticalPadding: 10,
          horizontalPadding: 10,
          maximumWidth: 10,
          borderRadius: 10,
        },
      ],
      snackbars: [
        {
          id: `snackbar$$${1}`,
          snackbarStyleName: "New Snackbar Style",
          timeToDisplay: 5,
          verticalPadding: 20,
          horizontalPadding: 20,
          maxWidth: 376,
          borderStyle: OutlineOptions.NONE,
          borderRadius: 0,
          borderThickness: 0,
          textStyle: {
            id: "typo$$1",
            custom: {
              family: FontFamilyOptions.ROBOTO,
              weight: FontWeightOptionsLongListOfWeights.Weight300,
              size: 24,
              lineHeight: 10,
              letterSpacing: 1,
            },
          },
          textColor: { id: "color$$1", custom: "#000000" },
          backgroundColor: { id: "color$$1", custom: "#000000" },
          borderColor: { id: "color$$1", custom: "#000000" },
          iconClose: true,
          icon: {
            iconUrl: "",
            iconPosition: SettingPositionOptions.LEFT,
            iconSize: 16,
            iconSpacing: 16,
            iconMainColor: { id: "color$$1", custom: "#000000" },
            iconHoverColor: { id: "color$$1", custom: "#000000" },
          },
          snackbarAlignment: {
            horizontal: AlignmentHorizontalOption.LEFT,
            vertical: AlignmentVerticalOption.BOTTOM,
          },
          topOffset: 0,
          bottomOffset: 0,
          leftOffset: 0,
          rightOffset: 0,
          animationType: SnackbarAnimationType.NONE,
          customCss: "",
          slideDirection: SlideFromDirections.RIGHT,
        },
      ],
      options: [
        {
          id: "option$$1",
          optionName: "New Option Style",
          button: "button$$1",
        },
      ],
      accordions: [defaultAccordionPattern(1)],
      dropdowns: [defaultDropdownPattern(1)],
      tableCells: [defaultTableCellPattern(1)],
    },
    stylePages: {
      pages: {
        page: { background: { color: { id: "color$$4", custom: "#000000" } } },
        modal: { background: { color: { id: "color$$4", custom: "#000000" } } },
        maxWidth: 1444,
        headerFooter: {
          stickyHeader: false,
          fullWidthBgcFooter: true,
          fullWidthBgcHeader: true,
        },
      },
      section: {
        width: {
          selectedOption: TRowWidthOption.PERCENT,
          percent: 100,
          margin: { left: 0, right: 0 },
        },
        background: { color: { id: "color$$4", custom: "#000000" } },
        border: {
          style: OutlineOptions.NONE,
          width: { top: 0, bottom: 0, left: 0, right: 0 },
          color: { id: "color$$4", custom: "#000000" },
          radius: 0,
        },
        spacingBetweenSections: 0,
        spacingBetweenSlots: 0,
      },
      slot: {
        background: { color: { id: "color$$4", custom: "#000000" } },
        border: {
          style: OutlineOptions.NONE,
          width: { top: 0, bottom: 0, left: 0, right: 0 },
          color: { id: "color$$4", custom: "#000000" },
          radius: 0,
        },
      },
    },
    advanceSettings: {
      messages: {
        currencyShort: "zł",
        taxShort: "%",
        delivery_HOURS_24: "Dostawa w 24h",
        delivery_HOURS_48: "Dostawa w 48h",
        delivery_HOURS_72: "Dostawa w 72h",
        delivery_DAYS_4_5: "Dostawa w ciągu 4-5 dni",
        delivery_DAYS_6_8: "Dostawa w ciągu 6-8 dni",
        delivery_WEEKS_2: "Dostawa w ciągu 2 tygodni",
        delivery_NOT_AVAILABLE: "Termin dostawy nieznany",
        delivery_unknown: "Brak informacji o dostawie",
      },
      localeMessages: {
        default: {
          currencyShort: "zł",
          taxShort: "%",
          delivery_HOURS_24: "Dostawa w 24h",
          delivery_HOURS_48: "Dostawa w 48h",
          delivery_HOURS_72: "Dostawa w 72h",
          delivery_DAYS_4_5: "Dostawa w ciągu 4-5 dni",
          delivery_DAYS_6_8: "Dostawa w ciągu 6-8 dni",
          delivery_WEEKS_2: "Dostawa w ciągu 2 tygodni",
          delivery_NOT_AVAILABLE: "Termin dostawy nieznany",
          delivery_unknown: "Brak informacji o dostawie",
        },
      },
      settings: {
        backgroundUrl: "/static/images/placeholder.png",
        snackbarApiError: "snackbar$$1",
        searchRedirectUrl: "/search",
        googleMapApiKey: "",
        googleRecaptchaSecretKey: "",
        googleRecaptchaSiteKey: "",
        unsubscribNewsletterSuccesUrl: "/unsubscrib_newsletter_succes",
        dynamicBoxImagePlug: "",
        loginUrl: "/login",
        resetPasswordUrl: "/reset_password",
        faviconURL: "",
        redirectForEmptyResults: "/",
        clipboard_icon_on: "",
        clipboard_icon_off: "",
        ribbons_label_typography: "typo$$1",
        ribbons_label_color: "color$$1",
        new_ribbon_background_color: "color$$2",
        bestseller_ribbon_background_color: "color$$3",
        promotion_ribbon_background_color: "color$$3",
        inpost_geowidget_token: "",
        collect_events: "true",
        bar_message_display_time: "5",
      },
      customCss: "",
      customJs: "",
      trackingScript: "",
      allowedReferralList: [""],
    },
    systemPages: {
      page404: {
        id: -1,
      },
      page500: {
        id: -1,
      },
      logOutPage: {
        id: -1,
        technicalLogOut: "/log_out_tech",
      },
      orderSummaryPage: {
        id: -1,
        orderSummaryDetails: "autopay-payment-completed",
      },
    },
  },
  mobile: null,
};

export const themeSettings = api.injectEndpoints({
  endpoints: (builder) => ({
    getThemeSettingsPageWizard: builder.query<ThemeStatePageWizard, void>({
      query: () => API.getThemeSettingsPageWizard.endpoint(),
      transformResponse: (response: IGetThemeSettingsResponse) => {
        const { content, mobileContent, typedContent, typedMobileContent } =
          response;

        let contentObj;
        let mobileContentObj;

        if (typedContent) {
          contentObj = {
            typography: JSON.parse(typedContent.typography),
            colorPalette: JSON.parse(typedContent.colorPalette),
            globalObjects: JSON.parse(typedContent.globalObjects),
            stylePages: JSON.parse(typedContent.stylePages),
            advanceSettings: {
              messages: typedContent.advanceSettings.messages,
              localeMessages: typedContent.advanceSettings.localeMessages,
              settings: typedContent.advanceSettings.settings,
              customCss: typedContent.advanceSettings.customCss,
              customJs: typedContent.advanceSettings.customJs,
              trackingScript: typedContent.advanceSettings.trackingScript,
              allowedReferralList:
                typedContent.advanceSettings.allowedReferralList,
            },
            systemPages: JSON.parse(typedContent.systemPages),
          };
        } else {
          contentObj = content && content !== "{}" ? JSON.parse(content) : null;
        }

        if (typedMobileContent) {
          mobileContentObj = {
            typography: JSON.parse(typedMobileContent.typography),
            colorPalette: JSON.parse(typedMobileContent.colorPalette),
            globalObjects: JSON.parse(typedMobileContent.globalObjects),
            stylePages: JSON.parse(typedMobileContent.stylePages),
            advanceSettings: {
              messages: typedMobileContent.advanceSettings.messages,
              localeMessages: typedMobileContent.advanceSettings.localeMessages,
              settings: typedMobileContent.advanceSettings.settings,
              customCss: typedMobileContent.advanceSettings.customCss,
              customJs: typedMobileContent.advanceSettings.customJs,
              trackingScript: typedMobileContent.advanceSettings.trackingScript,
              allowedReferralList:
                typedMobileContent.advanceSettings.allowedReferralList,
            },
            systemPages: JSON.parse(typedMobileContent.systemPages),
          };
        } else {
          mobileContentObj =
            mobileContent && mobileContent !== "{}"
              ? JSON.parse(mobileContent)
              : null;
        }

        return {
          basic: contentObj,
          mobile:
            mobileContentObj && Object.keys(mobileContentObj).length !== 0
              ? mobileContentObj
              : null,
        };
      },
      providesTags: ["SAVED_SETTINGS"],
    }),
    getThemeSettingsPortal: builder.query<IThemeState, "MOBILE" | "DESKTOP">({
      query: (type) => API.getThemeSettingsPortal.endpoint(type),
      transformResponse: (response: IGetThemeSettingsResponse) => {
        if (response.typedContent) {
          return ThemeSettingsSchemeBasic.parse({
            typography: JSON.parse(response.typedContent.typography),
            colorPalette: JSON.parse(response.typedContent.colorPalette),
            globalObjects: JSON.parse(response.typedContent.globalObjects),
            stylePages: JSON.parse(response.typedContent.stylePages),
            advanceSettings: {
              messages: response.typedContent.advanceSettings.messages,
              settings: response.typedContent.advanceSettings.settings,
              customCss: response.typedContent.advanceSettings.customCss,
              customJs: response.typedContent.advanceSettings.customJs,
              trackingScript:
                response.typedContent.advanceSettings.trackingScript,
              allowedReferralList:
                response.typedContent.advanceSettings.allowedReferralList,
            },
            systemPages: JSON.parse(response.typedContent.systemPages),
          });
        } else {
          return ThemeSettingsSchemeBasic.parse(
            response.content && response.content !== "{}"
              ? JSON.parse(response.content)
              : initialThemeSettings.basic
          );
        }
      },
      providesTags: ["LANGUAGE"],
    }),
    postThemeSettings: builder.mutation<void, ThemeStatePageWizard>({
      query: (theme) => {
        return {
          url: API.postThemeSettings.endpoint(),
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: {
            typedContent: {
              typography: JSON.stringify(theme.basic.typography),
              colorPalette: JSON.stringify(theme.basic.colorPalette),
              globalObjects: JSON.stringify(theme.basic.globalObjects),
              stylePages: JSON.stringify(theme.basic.stylePages),
              advanceSettings: {
                messages: theme.basic.advanceSettings.messages,
                localeMessages: theme.basic.advanceSettings.localeMessages,
                settings: theme.basic.advanceSettings.settings,
                customCss: theme.basic.advanceSettings.customCss,
                customJs: theme.basic.advanceSettings.customJs,
                trackingScript: theme.basic.advanceSettings.trackingScript,
                allowedReferralList:
                  theme.basic.advanceSettings.allowedReferralList,
              },
              systemPages: JSON.stringify(theme.basic.systemPages),
            },
            typedMobileContent:
              theme.mobile === null
                ? null
                : {
                    typography: JSON.stringify(theme.mobile.typography),
                    colorPalette: JSON.stringify(theme.mobile.colorPalette),
                    globalObjects: JSON.stringify(theme.mobile.globalObjects),
                    stylePages: JSON.stringify(theme.mobile.stylePages),
                    advanceSettings: {
                      messages: theme.mobile.advanceSettings.messages,
                      localeMessages:
                        theme.mobile.advanceSettings.localeMessages,
                      settings: theme.mobile.advanceSettings.settings,
                      customCss: theme.mobile.advanceSettings.customCss,
                      customJs: theme.mobile.advanceSettings.customJs,
                      trackingScript:
                        theme.mobile.advanceSettings.trackingScript,
                      allowedReferralList:
                        theme.mobile.advanceSettings.allowedReferralList,
                    },
                    systemPages: JSON.stringify(theme.mobile.systemPages),
                  },
          },
        };
      },
      invalidatesTags: ["SAVED_SETTINGS"],
    }),
  }),
});

export const {
  useGetThemeSettingsPortalQuery,
  useGetThemeSettingsPageWizardQuery,
  usePostThemeSettingsMutation,
} = themeSettings;

export const {
  getThemeSettingsPortal,
  getThemeSettingsPageWizard,
  postThemeSettings,
} = themeSettings.endpoints;
