//function extracting tenant value from the url
export const extractTenantFromUrl = (url: string): string | null =>
  new URLSearchParams(url).get("tenant");

//helper to get item from sessionStorage
export const getSessionStorageTenant = (): string | null => {
  if (typeof window !== "undefined") {
    return window.sessionStorage.getItem("tenant");
  }
  return null;
};

export const isThemeMode = (): boolean => {
  if (
    typeof window !== "undefined" &&
    window.sessionStorage.getItem("isTheme")
  ) {
    const themeMode = window.sessionStorage.getItem("isTheme");
    return themeMode === "true";
  }
  return false;
};

export const mapLanguageToLocale = (language: string) => {
  const languageMap = {
    en: "en_US",
    it: "it_IT",
    zh: "zh_CN",
  };
  return languageMap[language as keyof typeof languageMap] ?? language;
};

//this endpoint will be called with themeMode tenant, otherwise will be called with tenant "mock"
const WHITE_LISTED_ENDPOINTS_FOR_TENANT_FOR_THEME_MODE = [
  //Portal endpoits
  "getThemeSettingsPortal",
  "getPagePortal",
  "getPageUrlById",

  //Page builder endpoints
  "getPageWizard",
  "getTenantDomains",
  "getHeaderFooter",
  "postPages",
  "updatePage",
  "getPageUrlById",
  "getMediaParentFolderId",
  "getFilesWithFolders",
  "uploadFile",
  "postUploadFromURL",
  "postSaveLocalBoxAsGlobal",
  "putSaveGlobalBoxChanges",
  "postGlobalBoxes",
  "getGlobalBox",
  "getHeaders",
  "getFooters",
  "postThemeSettings",
  "getThemeSettingsPageWizard",
  "copyGlobalBox",
];

export const endpointsWithAuth = [
  "getCartByPortalUserToken",
  "getDeliveryAndPaymentByPortalUserToken",
  "getDeliveryAndPaymentByPortalUserTokenExtended",
  "getMyOrdersOrdersList",
  "getMyOrdersOrderDetails",
  "getMyAddresses",
  "getCustomerData",
  "getMyAccountConsents",
  "getConsents",
  "getMiniCartByPortalUserToken",
  "getMiniCartCountByPortalUserToken",
  "getLastSeenCount",
  "getWishList",
  "postGetWishList",
];

export const isThemeEndpoint = (endpoint: string): boolean =>
  WHITE_LISTED_ENDPOINTS_FOR_TENANT_FOR_THEME_MODE.includes(endpoint);
