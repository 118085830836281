import { PageContext } from "@ecp-boxes/helpers/pageContext";
import React from "react";

interface PageInPageContextType {
  url: string;
  pageContext?: PageContext;
}

export interface PageInPageProviderProps {
  pageContext?: PageContext;
  url: string;
}

export const PageInPageContext =
  React.createContext<PageInPageContextType | null>(null);

export const PageInPageProvider: React.FC<
  PageInPageProviderProps & { children: any }
> = ({ children, pageContext, url }) => {
  return (
    <PageInPageContext.Provider
      value={{
        pageContext,
        url,
      }}
    >
      {children}
    </PageInPageContext.Provider>
  );
};

export const usePageInPageContext = () => {
  const context = React.useContext(PageInPageContext);
  return context;
};
