export const DATE_FORMAT = "yyyy-MM-dd";

export const DESKTOP = "DESKTOP";
export const MOBILE = "MOBILE";
export const MOBILE_BREAKPOINT = 800;

export type TDeviceType = typeof DESKTOP | typeof MOBILE;

export type TErrorData = {
  code: number;
  message: string;
  serviceName: string;
};
