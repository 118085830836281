import classNames from "classnames";
import { useMemo, useState } from "react";
import { getImageNameFromUrl } from "../../../helpers/helpers";
import StyledImage from "../../../settingsPatterns/elements/StyledImage/StyledImage";
import { useLinkContentActions } from "../../../shared/hooks/useLinkContentAction";
import LinkWrapper from "../LinkWrapper/LinkWrapper";
import { checkImageType, imgResizer } from "./ImageWrapper.methods";
import { StyledSimplyImage } from "./ImageWrapper.styled";
import { IImageWrapperProps, ImgType } from "./ImageWrapper.types";
import Image from "./MyNextImage";

const ImageWrapper: React.FC<IImageWrapperProps> = (props) => {
  const [hasError, setHasError] = useState(false);
  const { getHref, getTarget, linkContentActions } = useLinkContentActions();

  const {
    imageUrl,
    seoDescription,
    imageFit,
    imageAlignment,
    opacity,
    parallax,
    width,
    height,
    link,
    imgTagAsBackground,
    className,
    setIsNextImageLoaded,
    handleSetTooltip,
    handleResetTooltip,
    tooltipEnabled,
    imageId,
  } = props;

  const memoizedSeoDesc = useMemo(
    () =>
      seoDescription ? seoDescription : getImageNameFromUrl(imageUrl ?? ""),
    [seoDescription, imageUrl]
  );

  if (!imageUrl || imageUrl === "/" || width === 0 || height === 0) return null;

  const imgType: ImgType = checkImageType({ ...props });
  const href = link ? getHref(link) : undefined;

  const handleImageLoadComplete = () => {
    setIsNextImageLoaded?.(true);
  };

  const tooltipId = tooltipEnabled ? `tooltip-${imageId}` : ``;

  switch (imgType) {
    case "next-resize": {
      return (
        <LinkWrapper
          href={href}
          openInNewTab={link?.goToPageSetting?.openInNewTab}
          className={className}
          onClick={link ? () => linkContentActions(link) : undefined}
        >
          {hasError ? (
            <img
              alt={memoizedSeoDesc}
              src={imageUrl}
              style={{ height: "unset !important" }}
            />
          ) : (
            <Image
              src={imgResizer(imageUrl, undefined, height ?? 0)}
              alt={memoizedSeoDesc}
              onLoad={() => {
                //setting to avoid flickering on image load
                handleImageLoadComplete();
              }}
              onError={() => setHasError(true)}
              style={{
                ...(opacity !== undefined && { opacity: opacity / 100 }),
                objectFit: imageFit ? "cover" : "contain",
                objectPosition: `${imageAlignment.horizontal} ${imageAlignment.vertical}`,
                fill: height ? "responsive" : "fill",
                height: height,
              }}
              width={width}
              loading="lazy"
              height={height}
              data-testid="next-image"
              className={className}
              data-tooltip-id={tooltipId}
              data-tooltip-place="top"
              data-tooltip-position-strategy="fixed"
              onMouseEnter={handleSetTooltip}
              onMouseLeave={handleResetTooltip}
            />
          )}
        </LinkWrapper>
      );
    }
    case "ecp-resize": {
      return (
        <LinkWrapper
          href={href}
          openInNewTab={link?.goToPageSetting?.openInNewTab}
          className={className}
          onClick={link ? () => linkContentActions(link) : undefined}
        >
          <StyledSimplyImage
            src={imgResizer(imageUrl, width, height)}
            alt={memoizedSeoDesc}
            opacity={opacity ?? 100}
            className={classNames("ecp-image", className)}
            data-testid="ecp-resize-image"
            objectPosition={`${imageAlignment.horizontal} ${imageAlignment.vertical}`}
            objectFit={imageFit ? "contain" : "cover"}
            backgroundMode={imgTagAsBackground}
            data-tooltip-id={tooltipId}
            data-tooltip-place="top"
            data-tooltip-position-strategy="fixed"
            onMouseEnter={handleSetTooltip}
            onMouseLeave={handleResetTooltip}
          />
        </LinkWrapper>
      );
    }
    case "backgorund-img": {
      return (
        <StyledImage
          className={className}
          href={href}
          target={link ? getTarget(link) : undefined}
          role="img"
          data-testid="background-image"
          aria-label={memoizedSeoDesc}
          alt={memoizedSeoDesc}
          onClick={link ? () => linkContentActions(link) : undefined}
          $settings={{
            backgroundUrl: imageUrl,
            fit: imageFit,
            alignment: imageAlignment,
            parallax: !!parallax,
            opacity: opacity,
            width: width,
          }}
          data-tooltip-id={tooltipId}
          data-tooltip-place="top"
          data-tooltip-position-strategy="fixed"
          onMouseEnter={handleSetTooltip}
          onMouseLeave={handleResetTooltip}
        />
      );
    }
    default: {
      return null;
    }
  }
};

export default ImageWrapper;
