import styled, { css } from "styled-components";
import composeCss from "../../settingsPatterns/composeCss";
import { TUrl } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import {
  IBoxVideoContent,
  IBoxVideoContentSettings,
  IBoxVideoDisplaySettings,
  IBoxVideoSettings,
  OrientationOption,
  RatioOption,
} from "./BoxVideo.types";
import { TITLE_CONTENT_SETTING_PATTERN } from "@ecp-boxes/settingsPatterns/settingsPatterns.utils";

export const generateBoxVideoStyles = ({
  $settings,
  url,
}: {
  $settings: IBoxVideoSettings["$settings"];
  theme: IBoxVideoSettings["theme"];
  url: TUrl;
}) => {
  const ratio = composeCss.ratioStyle($settings.ratio, $settings.orientation);
  return css`
    position: relative;
    padding-top: ${$settings.padding.top}px;
    padding-bottom: ${$settings.padding.bottom}px;
    ${!url.includes("tiktok.com") &&
    css`
      aspect-ratio: ${ratio};
    `}
    margin: auto;
    max-width: 100%;
    width: ${$settings.size.isProcents
      ? `${$settings.size.procents}%`
      : `${$settings.size.pixels * ratioToNumber(ratio)}px`};

    .video-container {
      &__video-icon {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #eeeeee;
      }

      &__title {
        margin-top: 30px;
      }
    }
  `;
};

export const StyledBoxVideo = styled.div<IBoxVideoSettings & { url: TUrl }>(
  (props) => generateBoxVideoStyles(props)
);

export const StyledControlBar = styled.div<{ progress: number }>(
  ({ progress }) => {
    return css`
      width: 100%;
      height: 50px;
      padding: 0 20px;
      background-color: #000000;
      margin-top: -50px;
      position: relative;
      z-index: 1;

      display: flex;
      align-items: center;
      gap: 20px;

      .control-bar {
        &__progress-bar {
          width: 100%;
          height: 12px;
          background-color: #323232;
          border-radius: 8px;
          overflow: hidden;
          cursor: pointer;

          &__progress {
            width: ${progress * 100}%;
            height: 100%;
            background-color: #699ff6;
            transition: width 0.1s linear;
          }
        }

        &__volume-container {
          position: relative;

          &__mute-icon,
          &__volume-icon {
            cursor: pointer;
            min-width: 32px;
          }

          &__volume-slider {
            position: absolute;
            top: -130px;
            left: 50%;
            transform: translateX(-50%);
          }
        }

        &__dropdown-icon,
        &__play-icon,
        &__pause-icon,
        &__fast-forward,
        &__rewind,
        &__full-screen {
          cursor: pointer;
          min-width: 32px;
        }
      }

      .dropdown-wrapper {
        width: auto;
      }
    `;
  }
);

export const StyledSpeedControlDropdown = styled.div`
  width: 115px;
  border-radius: 6px;
  padding: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  z-index: 10000;
  box-shadow: 0px 4px 16px rgba(196, 208, 216, 0.37);

  .control-bar__playback-rate-dropdown {
    &__option {
      width: 100%;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
    }

    &__option:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const BOX_VIDEO_DISPLAY_PATTERN = (): IBoxVideoDisplaySettings => ({
  padding: {
    top: 0,
    bottom: 0,
  },
  ratio: RatioOption.SIXTEEN_TO_NINE,
  size: { isProcents: true, procents: 100, pixels: 200 },
  orientation: OrientationOption.HORIZONTAL,
});

export const BOX_VIDEO_CONTENT_SETTINGS_PATTERN =
  (): IBoxVideoContentSettings => ({
    title: TITLE_CONTENT_SETTING_PATTERN(),
    video: {
      autoPlay: true,
      showVideoControls: true,
      loopVideo: true,
      volume: 100,
      playbackRate: 1,
      muted: true,
    },
  });

export const BOX_VIDEO_CONTENT_PATTERN = (): IBoxVideoContent => ({
  coverImage: "",
  videoURL: "",
});

export const BOX_VIDEO_PATTERN = () => ({
  ...BOX_VIDEO_DISPLAY_PATTERN(),
  ...BOX_VIDEO_CONTENT_SETTINGS_PATTERN(),
});

export const ratioToNumber = (val: string) => {
  const numbers = val.split("/");
  return +numbers[0] / +numbers[1];
};
