export const GET_NOTIFICATION_CONSENTS_RESPONSE = {
  consents: [
    {
      consentId: 1,
      definition: "Czy chcesz otrzymywać powiadomienia o promocjach?",
      platform: true,
      email: true,
      sms: false,
    },
    {
      consentId: 2,
      definition: "Czy chcesz otrzymywać powiadomienia o nowych produktach?",
      platform: true,
      email: false,
      sms: true,
    },
  ],
};
