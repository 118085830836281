import { Slide, cssTransition, toast, ToastOptions } from "react-toastify";
import { useTheme } from "styled-components";
import {
  AlignmentHorizontalOption,
  AlignmentVerticalOption,
  IGlobalSnackbarObject,
  IThemeState,
  TSnackbarId,
  TSnackbarReadoutValue,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { isNotEmptyMessage } from "../../../helpers/isNotEmptyMessage";
import {
  convertSnackbarIdToValues,
  isColorTransparent,
} from "../../../settingsPatterns/settingsPatterns.methods";
import { ReactComponent as CloseIcon } from "../../icons/close.svg";
import StyledText from "../../styleElements/StyledText/StyledText";
import { GlobalStyle, StyledPortalSnackbar } from "./Snackbar.styled";
import { IPortalSnackbarProps } from "./Snackbar.types";

export const PortalSnackbar: React.FC<IPortalSnackbarProps> = ({
  snackbarValue,
  message,
  closeToast,
  theme,
}) => {
  const { icon, textStyle, textColor, iconClose } = snackbarValue;

  const isSvg = icon.iconUrl?.endsWith(".svg");

  return (
    <StyledPortalSnackbar
      {...snackbarValue}
      theme={theme}
      isEmptySnackbar={!isNotEmptyMessage(message)}
      onClick={() => toast.dismiss()}
    >
      <GlobalStyle
        snackbarValue={snackbarValue}
        offset={{
          top: snackbarValue?.topOffset,
          right: snackbarValue?.rightOffset,
          bottom: snackbarValue?.bottomOffset,
          left: snackbarValue?.leftOffset,
        }}
      />
      <div className="content">
        <StyledText
          className="message"
          renderAs="p"
          $settings={{
            font: textStyle,
            text: { color: textColor },
          }}
          theme={theme}
          show={isNotEmptyMessage(message)}
        >
          {message}
        </StyledText>
        {snackbarValue.icon.iconUrl &&
          (isSvg &&
          !isColorTransparent(
            snackbarValue.icon.iconMainColor,
            theme.colorPalette
          ) ? (
            <div
              role="icon"
              style={{
                width: icon.iconSize,
                height: icon.iconSize,
                order: icon.iconPosition === "LEFT" ? -1 : 1,
              }}
              className="svg"
              data-testid="snackbar-icon"
            >
              <i />
            </div>
          ) : (
            <i />
          ))}
      </div>
      {iconClose && (
        <button onClick={closeToast} className="snackbar-icon-close">
          <CloseIcon />
        </button>
      )}
    </StyledPortalSnackbar>
  );
};

export const useOpenPortalSnackbar = () => {
  const theme = useTheme() as IThemeState;
  return {
    openPortalSnackbar: (
      snackbar: TSnackbarReadoutValue | TSnackbarId,
      message: string,
      config: {
        showToastUnderHeader?: boolean;
      } = {}
    ) => {
      const { showToastUnderHeader = false } = config;
      renderPortalSnackbar(
        convertSnackbarIdToValues(snackbar, theme.globalObjects.snackbars),
        message,
        theme,
        showToastUnderHeader
      );
    },
  };
};

const Custom = cssTransition({
  enter: "toast-custom-enter",
  exit: "toast-custom-exit",
});

let lastSnackbarId: string | number | null = null;

export const renderPortalSnackbar = (
  snackbar: IGlobalSnackbarObject,
  message: string,
  theme: IThemeState,
  showToastUnderHeader?: boolean
) => {
  const commonConfig = {
    autoClose: snackbar.timeToDisplay * 1000,
    hideProgressBar: true,
    transition: Slide,
    draggable: false,
  } satisfies ToastOptions;

  const toastUnderHeaderConfig = {
    ...commonConfig,
    position: "top-center",
    containerId: "PortalHeaderSnackbar",
  } satisfies ToastOptions;

  const regularToastConfig = {
    ...commonConfig,
    containerId: "PortalSnackbar",
    transition: Custom,
    closeOnClick: isCustomPosition(
      alignmentToSnackbarPosition(
        snackbar.snackbarAlignment.vertical,
        snackbar.snackbarAlignment.horizontal
      )
    ),
    position:
      alignmentToSnackbarPosition(
        snackbar.snackbarAlignment.vertical,
        snackbar.snackbarAlignment.horizontal
      ) ?? "bottom-left",
  } satisfies ToastOptions;

  if (lastSnackbarId !== null) {
    toast.dismiss(lastSnackbarId);
  }

  toast.dismiss(regularToastConfig.containerId);

  lastSnackbarId = toast(
    ({ closeToast }) => (
      <PortalSnackbar
        snackbarValue={snackbar}
        message={message}
        closeToast={closeToast}
        theme={theme}
      />
    ),
    showToastUnderHeader ? toastUnderHeaderConfig : regularToastConfig
  );

  return lastSnackbarId;
};

export const alignmentToSnackbarPosition = (
  vertical: AlignmentVerticalOption,
  horizontal: AlignmentHorizontalOption
): ExtendedToastPosition => {
  return `${vertical}-${horizontal}`.toLowerCase() as ExtendedToastPosition;
};

export const isCustomPosition = (position: ExtendedToastPosition): boolean =>
  position === "center-center" ||
  position === "left-center" ||
  position === "right-center";
