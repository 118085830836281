import { LanguageType } from "../themeSettings/translation.types";
import { IPortalBoxData } from "./portal.types";

export type TRegularBoxType =
  | "HTML"
  | "MULTI_MENU"
  | "SLIDER"
  | "TEXT"
  | "IMAGE"
  | "VIDEO"
  | "BANNER"
  | "PRODUCT_SLIDER"
  | "ARTICLE_LIST"
  | "RICH_TEXT"
  | "BREADCRUMB"
  | "BUTTON"
  | "SEARCH_RESULTS"
  | "SYSTEM"
  | "SEARCH_FILTERS"
  | "PRODUCT_ATTRIBUTES"
  | "PRODUCT_VARIANT"
  | "GET_DATA"
  | "ACCORDION"
  | "LIST_OF_BUTTONS"
  | "GET_TABLE_DATA"
  | "SEARCH_RESULTS_B2B"
  | "TILES_SLIDER"
  | "COMPARE"
  | "FACETS_VARIANTS";

export type TSystemBoxType =
  | "MYACCOUNT_CONSENTS"
  | "MYACCOUNT_HEADER"
  | "LOGIN"
  | "REGISTER"
  | "CONTACT_FORM"
  | "REMIND_PASSWORD"
  | "PHOTO_GALLERY"
  | "ARTICLE_CONTENT"
  | "CART_STEP_ONE"
  | "CART_STEP_TWO"
  | "CART_STEP_TWO_EXTENDED"
  | "NEWSLETTER"
  | "SEARCH_BAR"
  | "ORDER_SUMMARY"
  | "MYACCOUNT_MYORDERS"
  | "MYACCOUNT_ORDER_DETAILS"
  | "MYACCOUNT_MYDATA"
  | "MINI_CART"
  | "ADD_TO_CART"
  | "SHOW_PAGE"
  | "MYACCOUNT_ADDRESSES"
  | "STORE_LOCATOR"
  | "ADD_TO_CART_QUANTITY"
  | "DROPDOWN"
  | "CONTRACTORS"
  | "CART_B2B"
  | "B2B_ADD_TO_CART_QUANTITY"
  | "B2B_CART_SUMMARY"
  | "B2B_ADD_TO_CART_UNIT"
  | "MINI_CART_B2B"
  | "COMPARE_HEADER"
  | "PAYPAL_PAYMENT"
  | "STEP_BAR"
  | "VERIFY_PHONE_NUMBER"
  | "CHANGE_PHONE_NUMBER"
  | "CHANGE_PASSWORD"
  | "CLIENT_NOTIFICATIONS"
  | "CURRENCY_DROPDOWN"
  | "LANGUAGE_SWITCHER";

export type IBoxData = {
  content: Record<string, any>;
  contentSettings: Record<string, any> | null;
  displaySettings: Record<string, any> | null;
  messages: Record<string, any>;
  settings: Record<string, any>;
  mobileSettings: Record<string, any>;
  mobileContentSettings: Record<string, any> | null;
  mobileDisplaySettings: Record<string, any> | null;
};

export interface GenericBoxData<
  content,
  contentSettings,
  displaySettings,
  messages,
  settings,
> {
  content: content;
  contentSettings: contentSettings;
  displaySettings: displaySettings;
  messages: messages;
  settings: settings;
}

export interface IPortalToPageBuilderBoxType<
  T extends IPortalBoxData = IPortalBoxData,
> {
  content: T["content"];
  contentSettings: T["contentSettings"];
  displaySettings: T["displaySettings"];
  localeMessages: { [key: LanguageType]: T["messages"] };
  settings: T["settings"];
  mobileSettings: T["settings"];
  mobileContentSettings: T["contentSettings"];
  mobileDisplaySettings: T["displaySettings"];
}

export enum PageCategoryType {
  REGULAR = "REGULAR",
  HEADER = "HEADER",
  FOOTER = "FOOTER",
  PRODUCT = "PRODUCT",
  ARTICLE = "ARTICLE",
  PRODUCT_CATEGORY = "PRODUCT_CATEGORY",
}
